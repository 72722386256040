.collapsable-section{
            margin-bottom: 32px;
        .collapsable-section-title{
            border-bottom: 1px solid var(--Border-primary-hover, #BABABA);
            background: var(--Background-secondary-selected, rgba(0, 0, 0, 0.16));
            display: flex;
            height: 32px;
            align-items: center;
            color: var(--Foreground-primary, #1F1F1F);
            font-family: ABBvoice;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
        .collapsable-section-icon{
            display: flex;
            width: 32px;
            height: 32px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    
    .collapsable-section-content{
        padding: 16px;
        background: var(--Background-stateless-alternative, #F5F5F5);


    }
    &.collapsed{
        .collapsable-section-content{
            display: none;
        }
    }
}