.excelreport-container {
    .table-responsive{
      overflow-y: auto;
      //max-height: 80vh;
    }

    .table-responsive::-webkit-scrollbar-track
    {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 15px;
    background-color: #FFFFFF !important;
    } 
    .table-responsive::-webkit-scrollbar-thumb
    {
    border-radius: 10px;
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #e8e8e8;
    }
    .table-responsive::-webkit-scrollbar-thumb:horizontal{
        background-color: #e8e8e8;
        border-radius: 10px;
    }
    .table-responsive::-webkit-scrollbar{
        height: 10px;
        width: 10px;
        background: #FFFFFF !important;
    }

      .table {
        th {
          border-top: 0px;
          border-bottom: 2px solid #afafaf;
          font-family: mediumFont;
          font-weight: 500;
          color: #696969;
          font-size: 14px;
          position: sticky; top: 0;
          background-color: #ffffff;
          text-align: center;
          //vertical-align: middle;
        }
        td {
          font-family: normalFont;
          //font-weight: 400;
          white-space: pre-line;
          padding: 6px 4px;
          color: #1F1F1F;
          font-size: 13px;
        }
        th {
          cursor: default;
          pointer-events: none;
          padding: 8px 4px;
          min-width: 70px;
        }      
        td:nth-child(1),
        th:nth-child(1) {
          min-width: 80px;
          text-align-last: center;
        }
        td:nth-child(2),
        th:nth-child(2) {
          max-width: 180px;
          word-wrap: break-word;
        //   text-align-last: center;
        }
        th:nth-child(3) {
          min-width: 90px;
        }
        th:nth-child(4),
        th:nth-child(5),
        th:nth-child(6),
        th:nth-child(7)
        {
            min-width: 120px;
          }
          
          td:nth-child(8),
        td:nth-child(3),
        td:nth-child(2) {
          text-align-last: center;
          vertical-align: middle;
        }
      }
    //   .pagination {
    //     float: right;
    //     margin: 8px 0px;
    //     .pagecount {
    //       line-height: 24px;
    //     }
    //   }
      
      .btn-link {
        width: 16px;
        padding: 0;
        line-height: normal;
        vertical-align: top;
        text-decoration: none !important;
      }
      .btn-link:hover {
        color: #0056b3;
        text-decoration: none !important;
      }
      .ellipsetxt {
        width: 67px;
        font-size: 12px;
      }
      .sortablecon:hover {
        .sortable {
          width: 16px;
          display: inline-block;
          height: 14px;
          vertical-align: middle;
          background-image: url("../../assets/images/arrow_up.svg");
          background-repeat: no-repeat;
          background-position: right;
          vertical-align: middle;
        }
      }
      .active-sort {
        border-bottom: 2px solid #0056b3 !important;
      }       
    }