.horizental-seperator {
  padding-left: 5px;
  border-left: 1px solid #d0d0d0;
  margin-left: 5px;
  height: 30px;
}
.datePickerContainer {
  margin-bottom: 0px;
  gap:4px;
}
.date-filter-selection {
  border-bottom: 1px solid #a0a0a0;
  padding: 16px 8px;
  margin-bottom: 0px;
  .date-picker-datemax-width {
    max-width: 174px !important; //250px;
  }
  .date-picker-timemax-width {
    max-width: 90px;
    .rw-widget-picker {
      border-left: 0px;
    }
  }
  .date-range-btn-con {
    max-width: 280px;
  }
  .daterange-backbtn {
    max-width: 26px;

    margin-right: 7px;
    
    .disabled {
      pointer-events: none;
      opacity: 0.5;
      button {
        color: #464646;
      }
      // &:hover {
      //   background: #464646;
      // }
    }
  }
  .showingtxt {
    max-width: 65px;
    height: 30px;
    line-height: 30px;
    font-family: mediumFont;
    font-size: 14px;
  }
  .date-seperator {
    padding: 0px 5px;
    line-height: 30px;
  }
  .smallbtn {
    max-width: 77px;
    height: 30px;
    font-family: mediumFont;
    font-size: 14px;
  }
  .rw-input {
    padding: 0 5px;
  }
  .datePickerContainer .rw-widget-picker {
    height: 30px;
    font-size: 16px;
  }
  .smallButton {
    height: 30px;
    font-family: mediumFont;
    font-size: 14px;
    max-width: 68px;
    width: auto;
    padding: 0 8px;
    margin-left: 8px;
  }
  .duration {
    font-family: mediumFont;
    font-size: 14px;
    display: inline-block;
    margin-right: 0;
  }
  .datediff {
    margin-left: 8px;
    line-height: 26px;
  }
}
.date-tabs-container {
  .datepickervalue {
    cursor: pointer;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
    button {
      color: #464646;
    }
  }
  .datePickerContainer {
    .disabled {
      opacity: 1;
    }
  }
  .rw-state-focus > .rw-widget-container {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 320px) {
  .date-filter-selection {
    .duration {
      font-size: 14px;
    }
  }
}
