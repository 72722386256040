.fleetmgmtsidemenu {
  background-color: #fafafa;
  padding: 8px;
  //height: 1094px;
  overflow: auto;
  position: fixed;
  width: 23%;
  .sidemenulist-scroll {
    max-height: 23vh;
    overflow: auto;
  }
  .sidemenusitelist-scroll {
    max-height: 55vh;
    overflow: auto;
  }
  .rbt {
    width: calc(100% - 35px);
    display: inline-block;
    .rbt-aux {
      align-items: center;
      display: flex;
      bottom: 0;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 34px;
    }
    .rbt-close {
      z-index: 1;
    }
    .dropdown-menu {
      border-radius: 0;
    }
    .dropdown-item {
      font-size: 12px;
      font-family: mediumFont;
    }
    .rbt-input-main {
      height: 37px;
      border-bottom: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding: 5px;
      background: transparent;
      font-family: mediumFont;
      font-size: 14px;
    }
    .form-control:focus {
      box-shadow: none;
    }
  }
  .addfleet {
    padding: 8px;
    font-family: mediumFont;
    font-size: 14px;
    cursor: pointer;
    margin-top: 8px;
    img {
      padding-right: 8px;
    }
  }
  .addfleetactive {
    box-sizing: border-box;
    background-color: #ddedfd;
    display: flex;
    align-items: center;
  }
  .FleetListcontainer {
    padding: 8px;
    cursor: pointer;
    margin: 0 0 2px;
    background-color: #f0f0f0;
    width: 100%;
    text-align: left;
    border-left: 0px solid transparent;
    font-family: mediumFont;
    font-size: 14px;
    &:hover {
      border-left: 0px solid #0c74da;
      box-sizing: border-box;
      background-color: #ddedfd;
    }
  }

  .fleetlistactive {
    border-left: 0px solid #0c74da;
    box-sizing: border-box;
    background-color: #ddedfd;
    //pointer-events: none;
  }

  .FleetListactive {
    border-left: 0px solid #0c74da;
    box-sizing: border-box;
    background-color: #ddedfd;
  }
}
.accordion .accordion-item .title:after {
  font-size: 10px;
  color: #787878;
}

#sidemenuserch {
  background-color: transparent;
  width: 99%;
}

.sidemenu-vehicle-con-filter {
  margin-top: 10px;
  .sidemenufilterdropdown {
    box-sizing: border-box;
    font-size: 14px;
    font-family: mediumFont;
  }
  .sidemenufilterdropdown .rw-list-option {
    padding: 5px;
  }
  .sidemenufilterdropdown .rw-list-option.rw-state-selected,
  .sidemenufilterdropdown .rw-list-option.rw-state-selected:hover {
    background-color: #2e92fa;
    border-color: #2e92fa;
    color: white;
  }
  .filterButtons {
    background: #ffffff;
    color: #464646;
    text-decoration: none;
    border-radius: 0px;
    font-size: 13px;
    font-family: mediumFont;
    min-height: 40px;
    border: 1px solid #d0d0d0;
    display: -webkit-flex;
    display: flex;
    min-width: auto !important;
    width: 100%;
    padding: 5px;
    border-left: 1px solid transparent;
  }
  .rw-widget-picker {
    min-height: 40px;
  }
  .filterButtonsContainer {
    text-align: center;
    max-width: 40px;
  }
  #side-menu-vehicles-dropdown {
    flex-grow: 8;
  }
  .graystar {
    background: #ffffff;
    text-decoration: none;
    font-size: 30px;
    font-family: mediumFont;
    min-height: 40px;
    border: 1px solid #d0d0d0;
    width: 100%;
    padding: 5px;
    border-left: 1px solid transparent;
  }
}

.innersearchfieldcon {
  border-bottom: 1px solid #d0d0d0;
  // margin-right: 4%;
  // margin-left: 5px;

  .search-btn {
    border: none;
    background: none;
    cursor: pointer;
    float: right;
    height: 37px;
  }
}

.fade:not(.show) {
  display: none;
}

.accordians-fleetsetup {
  color: #464646;
  font-family: "mediumFont";
  margin-bottom: 1rem;
}
.accordians-fleetsetup {
  padding: 0px 0px 0px 0px;
  .accordion {
    //width: 100%;
    border: 0;
    //background-color: #f0f0f0;
    .accordion-item {
      //width: 100%;
      border-bottom: 2px solid #fff;
      .title {
        //width: 100%;
        font-size: 14px;
        //font-weight: 600;
        padding: 0px 8px;
        height: 34px;
        line-height: 34px;
        background-color: #fafafa;
        border-radius: 0;
        border-left: 0px solid transparent;
        background-image: url("../../assets/images/site_loc.svg");
        background-repeat: no-repeat;
        background-position: left 5px center;
        padding-left: 30px;
        background-size: 16px 16px;
        &:hover {
          border-left: 0px solid #0c74da;
          box-sizing: border-box;
          background-color: #e6e6e6;
        }
      }
      .title:after {
        margin-top: 5px;
        font-size: 14px;
        color: #474747;
        content: url(../../assets/images/caretdown_16.svg) !important;
        margin-right: 10px;
      }
      .panel {
        //width: 100%;
        background-color: #fafafa;
        padding: 0px 0px 0px 0px;
        font-size: 16px;
        font-family: "mediumFont";
        padding-left: 0px;
      }
    }
  }
  .accordian-content {
    border-bottom: 0px solid #cecece;
    margin-bottom: 0;
    background: #f5f5f5;
    padding: 10px 5px;
  }
  .title-energy {
    padding: 10px 0;
    border-bottom: 1px solid #1f1f1f;
    margin-bottom: 5px;
  }
  .subtitle-energy {
    padding: 10px 5px;
    border-bottom: 1px solid #a0a0a0;
    margin-bottom: 0px;
  }
  .add-margin {
    margin-top: 42px;
  }
  .addfleet {
    padding: 0px 8px 8px 8px;
  }
  .addfleetactive {
    box-sizing: border-box;
    background-color: #ddedfd;
  }
}

.accordiontitleicon {
  padding-right: 8px;
}

.accordiontitlevalue {
  color: #2e92fa;
}

.accordians-fleetsetup .accordion .accordion-item.active .title:after {
  content: url(../../assets/images/caretup_16.svg) !important;
}

.fleetmgmtsidemenu {
  .sitelistcon {
    margin-top: 16px;
  }
}

.accordians-fleetsetup {
  .accordion__button {
    padding: 8px 8px 8px 26px !important;
    background-image: url("../../assets/images/site_loc.svg");
    background-repeat: no-repeat;
    background-position: left 5px center;
    font-size: 14px;
    font-family: "mediumFont";

    &:hover {
      border-left: 0px solid #0c74da;
      box-sizing: border-box;
      background-color: #e6e6e6;
    }
    &:focus {
      outline: none !important;
    }
  }
  .accordion__button:after {
    margin-top: 5px;
    font-size: 14px;
    color: #474747;
    content: url(../../assets/images/caretdown_16.svg) !important;
    margin-right: 10px;
    float: right;
  }

  .accordion__button[aria-expanded="true"]:after {
    content: url(../../assets/images/caretup_16.svg) !important;
  }

  .accordion__button:before {
    display: none !important;
  }
  [aria-expanded="true"] {
    box-sizing: border-box;
    background-color: #e6e6e6;
  }
  .active-site > .accordion__button {
    box-sizing: border-box;
    background-color: #ddedfd;
  }
  .accordion__panel {
    background: #f5f5f5;
    padding: 10px 5px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  color: #464646;
}
.addFleetplus {
  margin-right: 8px;
}
#fSetuplist {
  max-height: 55vh;
}
