.containerreport {
  height: 100%;
}

.containerreporttitlebar {
  background-color: #ddedfd;
  line-height: 40px;

  .reportview-title {
    color: #004c97;
    font-family: ABBvoice;
    font-size: 18px;
    font-weight: 600;
    vertical-align: middle;
  }
}

.containerreportpreviewtitlebar {
  background-color: #ddedfd;
  line-height: 40px;

  .reportview-title {
    color: #004c97;
    font-family: ABBvoice;
    font-size: 18px;
    font-weight: 600;
    vertical-align: middle;
  }
}

.reportcontent {
  width: 100%;
  height: 25%;
  background-color: #ffffff;
  padding: 1em;
  margin-bottom: 5px;

  .fleetselection {
    .selectfleet {
      color: #464646;
      font-size: 13px;
      font-family: normalFont;
      margin-top: 5px;
    }

    .dropdownfleetlist {
      //width:25%;
      font-size: 13px;
      padding-top: 7px;
      font-family: mediumFont;
      //z-index: 1;
    }

    .fleettitle {
      //width:25%;
      margin-top: 7px;
      font-size: 13px;
      padding-top: 7px;
      font-family: mediumFont;
      border-radius: 0px;
    }
  }

  .datePickerTextbox {
    max-width: 92%;

    //margin-bottom: 15px;
    .selectfleet {
      color: #464646;
      font-size: 13px;
      font-family: normalFont;
      margin-top: 5px;
      margin-bottom: 9px;
    }

    .timevalue {
      border: 1px solid #d3d3d3;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 5px;
      padding-left: 5px;
      line-height: 28px;

      .datepickervalue {
        width: 100%;
        min-height: 30px;
        border: none;
        // padding-left: 5px;
        font-size: 13px;
        font-family: normalFont;
        background-image: url("../../assets/images/emailalert/datepicker.svg");
        background-repeat: no-repeat;
        background-position: right;
        padding-right: 20px;
        margin-right: 2px;
      }
    }
  }

  .buttongenerate {
    margin: 27px 0px 0px 0px;
    box-sizing: border-box;
    height: 37px;
    width: 100%;
    max-width: 120px;
    border: 1px solid #0c74da;
    background-color: #0c74da;
    border-radius: 0px;
    color: #fff;
    font-family: mediumFont;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    padding: 0px 0px;
  }

  .buttongenerate2 {
    font-size: 13px;
    font-family: mediumFont;
    color: rgba(0, 76, 151, 0.5);
    //display: flex;
    //flex-direction: row;
    justify-content: center;
    align-items: center;
    //padding: 8px 16px;
    gap: 4px;
    margin: 5px 0px 5px 5px;
    //position: absolute;
    width: 100px;
    height: 32px;
    // right: 24px;
    // bottom: 24px;
    background: rgba(12, 116, 218, 0.15);
    border: 1px solid rgba(12, 116, 218, 0.15);
  }
}

.reportview-container {
  width: 100%;
  //height: 85vh;
  background-color: #ffffff;
  padding: 1em;
}

.reportview-title {
  font-size: 16px;
  font-weight: 700;
}

.reportview-menubar {
  display: flex;
  justify-content: space-between;
}

.generatedreportcontainer {
  //margin: 0px 12% 0px 12%;
  padding: 7% 10% 7% 10%;
  background-color: #fff;
  text-align: justify;
  pointer-events: none;

  li {
    margin-top: 7px;
    margin-bottom: 7px;
    font-family: normalFont;
  }

  h4 {
    margin-top: 7px;
    margin-bottom: 7px;
    // font-size: 16px;
    font-family: mediumFont;
  }

  h5 {
    //font-size: 14px;
    font-family: mediumFont;
  }

  h6 {
    font-family: mediumFont;
  }

  .reddash {
    color: transparent;
    font-size: 22px;
    border-top: 8px solid red;
  }

  .reportmainimg {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;

    .mainimg {
      width: 80%;
      height: 45%;
    }
  }

  .description {
    margin-left: 23px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: normalFont;

    .reportcondmonitorimg {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;

      .condmonitorimg {
        width: 64%;
        height: 40%;
      }
    }

    .fleetkpitable {
      margin-top: 21px;
      margin-bottom: 21px;
    }

    table,
    th,
    td {
      border: 1px solid #000;
      border-left: transparent;
      border-right: transparent;
      padding: 5px 10px 5px 10px;
      font-size: 14px;
      font-family: normalFont;
    }

    th {
      border-top: 3px solid #000;
    }
  }

  .reportview-fleet {
    width: 100%;
    background-color: #fff;
    padding: 1em;
  }

  .producttitle {
    color: #464646;
    font-size: 16px;
    font-family: mediumFont;
    margin-top: 5px;
    margin-left: 16px;
  }

  .reportview-product {
    width: 95%;
    background-color: #fff;
    padding: 1em;
    text-align: -webkit-center;
  }

  .reportview-productreliability {
    width: 100%;
    background-color: #fff;
    padding: 1em;
    //padding: 1em;
    // text-align: -webkit-center;
    //margin-left: 45px;
  }
}

.excelreportcontainer {
  //margin: 0px 12% 0px 12%;
  padding: 2% 2% 2% 2%;
  background-color: #fff;
  text-align: justify;

  //pointer-events:none;
  li {
    margin-top: 7px;
    margin-bottom: 7px;
    font-family: normalFont;
  }

  h4 {
    margin-top: 7px;
    margin-bottom: 7px;
    // font-size: 16px;
    font-family: mediumFont;
  }

  h5 {
    //font-size: 14px;
    font-family: mediumFont;
  }

  h6 {
    font-family: mediumFont;
  }

  .reddash {
    color: transparent;
    font-size: 22px;
    border-top: 8px solid red;
  }

  .reportmainimg {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;

    .mainimg {
      width: 80%;
      height: 45%;
    }
  }

  .description {
    margin-left: 23px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: normalFont;

    .reportcondmonitorimg {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;

      .condmonitorimg {
        width: 64%;
        height: 40%;
      }
    }

    .fleetkpitable {
      margin-top: 21px;
      margin-bottom: 21px;
    }

    table,
    th,
    td {
      border: 1px solid #000;
      border-left: transparent;
      border-right: transparent;
      padding: 5px 10px 5px 10px;
      font-size: 14px;
      font-family: normalFont;
    }

    th {
      border-top: 3px solid #000;
    }
  }

  .reportview-fleet {
    width: 100%;
    background-color: #fff;
    padding: 1em;
  }

  .producttitle {
    color: #464646;
    font-size: 16px;
    font-family: mediumFont;
    margin-top: 5px;
    margin-left: 16px;
  }

  .reportview-product {
    width: 95%;
    background-color: #fff;
    padding: 1em;
    text-align: -webkit-center;
  }

  .reportview-productreliability {
    width: 100%;
    background-color: #fff;
    //padding: 1em;
    // text-align: -webkit-center;
    //margin-left: 45px;
  }
}

.reportcontainer {
  // width: 100%;
  // height: 25%;
  //background-color: #ffffff;
  padding: 1em;
  margin-top: 50px;

  // margin-bottom: 5px;
  .reportpreview {
    //height: 82vh;
    overflow: auto;
  }

  .reportpreview::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 15px;
    background-color: #ffffff !important;
  }

  .reportpreview::-webkit-scrollbar-thumb {
    border-radius: 10px;
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #e8e8e8;
  }

  .reportpreview::-webkit-scrollbar-thumb:horizontal {
    background-color: #e8e8e8;
    border-radius: 10px;
  }

  .reportpreview::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background: #ffffff !important;
  }

  .selectionpart {
    //margin-left: 8px;
    height: fit-content;
    background-color: #ffffff;
    padding: 0px 0px 41px 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;

    .reportconfig {
      color: #464646;
      font-size: 16px;
      font-family: mediumFont;
      margin: 12px 0px 12px 16px;
    }

    .fleetselection {
      .selectfleet {
        color: #000;
        font-size: 14px;
        font-family: normalFont;
        margin-top: 7px;
      }

      .dropdownfleetlist {
        //width:25%;
        font-size: 14px;
        //padding-top: 7px;
        font-family: normalFont;
        //z-index: 1;
      }

      .fleettitle {
        //width:25%;
        //margin-top: 7px;
        font-size: 14px;
        //padding-top: 7px;
        color: #000;
        font-family: normalFont;
        border-radius: 0px;
      }
    }

    .datePickerTextbox {
      //max-width: 92%;
      padding-right: 25px;

      //margin-bottom: 15px;
      .selectfleet {
        color: #464646;
        font-size: 14px;
        font-family: normalFont;
        margin-top: 7px;
        //margin-bottom: 9px;
      }

      .timevalue {
        border: 1px solid #d3d3d3;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 5px;
        padding-left: 5px;
        line-height: 28px;

        .datepickervalue {
          width: 100%;
          min-height: 30px;
          border: none;
          // padding-left: 5px;
          font-size: 14px;
          font-family: normalFont;
          background-image: url("../../assets/images/emailalert/datepicker.svg");
          background-repeat: no-repeat;
          background-position: right;
          padding-right: 20px;
          margin-right: 2px;
        }
      }
    }

    .buttongenerate {
      margin: 27px 0px 0px 0px;
      box-sizing: border-box;
      height: 37px;
      width: 100%;
      //max-width: 120px;
      border: 1px solid #0c74da;
      background-color: #0c74da;
      border-radius: 0px;
      color: #fff;
      font-family: mediumFont;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      text-align: center;
      padding: 0px 0px;
    }

    .buttondownload {
      margin: 14px 0px 0px 0px;
      box-sizing: border-box;
      height: 37px;
      width: 47%;
      // border: 1px solid #464646;
      background-color: #c8c8c899;
      border-radius: 0px;
      color: #464646;
      font-family: mediumFont;
      font-size: 12px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      padding: 0px 0px;
    }

    #buttondownload {
      border-radius: 0;
      border: 0;
    }

    .buttonemail {
      margin: 14px 0px 0px 0px;
      box-sizing: border-box;
      height: 37px;
      width: 47%;
      border: 0px solid #0c74da;
      background-color: #c8c8c899;
      border-radius: 0px;
      color: #464646;
      font-family: mediumFont;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      text-align: center;
      padding: 0px 0px;
      float:right
    }

    .dropdown-toggle {
      font-size: 12px;
    }

    .dropdown-item {
      font-size: 12px;
    }

    // .tab-content {
    //   padding: 16px;
    //   background-color: #fafafa;
    //   margin: 16px 0px;
    //   overflow-x: hidden;
    // }
    .nav-tabs {
      // max-width: 205px;
      // margin: 20px auto;
    }

    .nav-item {
      min-width: 50%;
    }

    .nav {
      margin: 0px 16px 0px 16px;
      padding-left: 0px;
    }

    .nav-link {
      text-align: center;
      //max-width: 50% !important;
      min-width: 100% !important;
      background-color: #eaeaea;
      padding: 0px 16px 0px 16px;
      min-height: 32px;
      background: #eaeaea;
      border-bottom: 1px solid #b8b8b8 !important;
      border-left: 1px solid #dedede !important;
      border-radius: 0px !important;
      box-sizing: border-box;
      width: auto;
      border-width: 1px 1px 1px 2px !important;

      &:hover {
        background-color: #ddedfd !important;
        border: 1px solid #62adfb !important;
      }
    }

    .nav-link.active {
      background-color: #ddedfd !important;
      border: 1px solid #62adfb !important;

      .tabtitle {
        color: #0c74da;
      }
    }

    .tabtitle {
      font-size: 14px;
      padding: 0;
      border: 0;
      color: #464646;
      font-family: normalFont;
      vertical-align: -webkit-baseline-middle;
    }

    .selectionesschkbox {
      display: flex;
      align-items: center;
      gap: 2px;
      padding-left: 20px;
      padding-top: 3px;

      .ckbx {
        margin-right: 8px;
      }

      .ckbx_label {
        color: #464646;
        font-size: 14px;
        line-height: 14px;
        font-family: normalFont;
        padding-top: 7px;
      }

      input[type="checkbox"] {
        -webkit-appearance: none;
        // -moz-appearance: none;
        appearance: none;
        width: 14px;
        height: 14px;
        border: 1px solid #bbbbbb;
        vertical-align: middle;

        &:checked {
          background-image: url("../../assets/images/sidemenu/checkboxcheck.svg");
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    .groupesschkbox {
      margin-right: 14px;
    }
  }

  .previewscreen {
    text-align: center;
    margin: 15% auto;
  }

  .previewscreen div:first-child {
    background-image: url("../../assets/images/abb_information.svg");
    background-repeat: no-repeat;
    padding-left: 10px;
    background-position: left;
    width: 87px;
    line-height: 26px;
    margin: 0 auto;
    margin-bottom: -16px;
  }
}