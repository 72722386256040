.pagination {
  button {
    border: 0px solid #bababa;
    margin: 0 4px !important;
    background: transparent !important;

    &:hover {
      background: #ebebeb !important;
    }

    &:disabled {
      pointer-events: none;
    }
  }

  .pagecount {
    margin: 0 4px !important;
  }
}

// .arrowicon{
//   padding: 5px 10px 5px 10px;
// }

.activeButton {
  font-weight: 600;
  border-bottom: 2px solid #2e92fa !important;
}

.diagnostic-container {
  .table-responsive {
    //max-height: 100vh;
    min-height: 70vh;
    //overflow: auto;

    //position: absolute;
    thead {
      position: sticky;
      top: 0;
      background-color: #ffffff;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    }
  }
}

// css to hide the last 2 columns of the diagnostic event list
#diagnosticTable td:nth-last-child(1),
#diagnosticTable th:nth-last-child(1) {
  display: none;
}

.filterrow {
  background-color: #f5f5f5;

  .sidemenufilterdropdown {
    box-sizing: border-box;
    font-size: 14px;
    font-family: ABBvoice;
    min-width: 160px;
  }

  .sidemenufilterdropdown .rw-list-option {
    padding: 5px;
  }

  .sidemenufilterdropdown .rw-list-option.rw-state-selected,
  .sidemenufilterdropdown .rw-list-option.rw-state-selected:hover {
    background-color: #2e92fa;
    border-color: #2e92fa;
    color: white;
  }

  .rw_reason_input {
    .rw-popup-container {
      width: 235px !important;
    }
  }
}

.pageInput {
  width: 40px;
  border: 0;
  border-bottom: 1px solid #e3e0e0;
  background: none;
}