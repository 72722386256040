.usagestab {
  // padding: 0px !important;

  // #produsgstabs-tab-usgdod {
  //   display: none;
  // }
  .tab-content {
    background: none;
    min-height: 490px;
    position: relative;

  }
  .weekCheckError{
    display: flex;
    justify-content: center;
    color: red;
    padding: 10%;
    font-size: 14px;
  }
  .nav-link {
    max-width: 200px !important;
    padding: 1px 5px !important;
  }

  .usgsubtabtitle {
    padding-left: 4px;
  }
  .usage-graph-radio {
    display: flex;
    label {
      font-family: "normalFont";
      font-size: 16px;
      background-image: url("../../assets/images/inactiveRadioLine.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 20px;
    }
    input:checked + label {
      background-image: url("../../assets/images/activeRadioLine.svg");
    }
  }
}
.heatmap-switch {
  position: absolute;
  right: 20px;
  z-index: 9;
  top: 0;
  font-size: 14px;
  font-family: "normalFont";
  cursor: pointer;
}
.form-check-inline{
  display: flex;
}