.filterContainer {
  background-color: #fafafa;
  // padding-top: 10px;
  padding: 8px;
  margin-top: 7px;
  .title {
    // padding: 0 15px;
    color: #464646;
    font-family: mediumFont;
    font-size: 16px;
    font-weight: 500;
    .sub {
      color: #464646;
      font-family: ABBvoice;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .dropdownsites {
    //padding: 0px 0 20px 15px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: mediumFont;
    // border-radius: 0 !important;
    // height: 40px;
    // width: 240px;
    //border: 1px solid #c8c8c8;
    //opacity: 0.8;
    //background-color: #ffffff;
    .rw-list-option.rw-state-selected,
    .rw-list-option.rw-state-selected:hover {
      background-color: #2e92fa;
      border-color: #2e92fa;
      color: white;
    }
  }
  .rw-widget-container {
    // background-color: #fff;
    // border: #ccc 1px solid;
    border-radius: 0px;
  }
}

.fleetContainer {
  background-color: #fafafa;
  padding: 8px;
  .title {
    // padding: 10px 0 1px 10px;
    color: #464646;
    font-family: mediumFont;
    font-size: 16px;
    font-weight: 500;
  }
}
.filterContainer .dropdownsites .rw-popup-transition {
  width: calc(100% - 30px);
  float: right;
  /* Firefox */
  width: -moz-calc(100% - 30px);
  /* WebKit */
  width: -webkit-calc(100% - 30px);
  /* Opera */
  width: -o-calc(100% - 30px);
  /* Standard */
  width: calc(100% - 30px);
}
.mapContainer {
  background-color: #fafafa;
  padding: 8px;
  .title {
    padding-bottom: 8px;
    color: #464646;
    font-family: mediumFont;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
  }
  .map {
    padding: 0px;
  }
}

// ESS new css
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  h1 {
    font-weight: bold;
  }
}

#rw_1_listbox > li:first-child,
#rw_2_listbox > li:first-child,
#rw_3_listbox > li:first-child {
  border-bottom: 1px solid #d2d2d2;
}

.filterButtons {
  background: #ffffff;
  color: #464646;
  text-decoration: none;
  border-radius: 0px;
  font-size: 12px;
  font-family: mediumFont;
  min-height: 38px;
  border: 1px solid #d0d0d0;
  display: -webkit-flex;
  display: flex;
  min-width: 110px;
  width: 100%;
  padding: 5px;
  &:hover {
    background-color: #daedff !important;
    border: 1px solid #0c74da !important;
  }
}
// .filterButtonsContainer {
//   max-width: 110px;
//   min-width: 110px;
// }

.dashboardFilterButton {
  box-sizing: border-box;
  height: 37px;
  width: 90%;
  max-width: 80px;
  font-family: mediumFont;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 0px;
  &:hover {
    color: #fff;
    background-color: #2e92fa;
    border-color: #2e92fa;
  }
  &:active {
    color: #fff;
    background-color: #2e92fa;
    border-color: #2e92fa;
  }
  &:disabled{
    background-color: #d0e0ef;
    pointer-events: none;
    opacity: 0.65;
    color: #6795c3;
  }
  &:enabled{
    pointer-events: auto;
    opacity: 1;
    color: #fff;
    background-color: #2e92fa;
    border:1px solid #0C74DA;
  }
}
.graystarDashboardFilter {
  line-height: 36px !important;
  padding: 0px 5px;
  font-size: 38px !important;
}

.activegraystarDashboardFilter {
  line-height: 36px !important;
  padding: 0px 5px;
  font-size: 38px !important;
}

.filterResetButton {
  display: flex;
  max-width: 120px;
}
.dashboardsearchfilter {
  height: 37px;
  border-bottom: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  background: transparent;
  width: calc(100% - 30px);
}
.dashboardsearchfiltercontainer {
  padding-left: 0.5%;
  border-left: 1px solid #d0d0d0;
  margin-left: 0.5%;
  .innersearchfieldcon {
    border-bottom: 1px solid #d0d0d0;
    margin-right: 0;
    margin-left: 5px;

    .search-btn {
      border: none;
      background: none;
      cursor: pointer;
      float: right;
      height: 37px;
    }
  }
}

.tooltip .arrow:before {
  border-top-color: #bababa !important;
}

.tooltip .tooltip-inner {
  background-color: #ffffff;
  color: #000000 !important;
  font-family: "normalFont";
  font-size: 12px;
  border: 1px solid #d0d0d0;
  border-radius: 0;
}

.filterContainer #dropdownsites_input {
  background-image: url("../../assets/images/abb_location_24.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  background-size: 24px 24px;
}
.filterContainer #dropdowncustomers_input {
  background-image: url("../../assets/images/abb_group_24.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  background-size: 24px 24px;
}
.filterContainer #dropdownfleets_input {
  background-image: url("../../assets/images/fleet.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  background-size: 24px 24px;
  border-right: 0px;
  .rw-widget-container {
    border-right: 0px;
  }
}

#favourite-tooltip {
  border-radius: 0px;
  padding: 5px;
}
// .selectmonitoringrange {
//   max-width: 195px;
//   font-size: 15px;
// }

// .fleetListContainer {
//   margin-top: 20px;
// }
// .fleetTitle {
//   font-family: boldFont;
//   background-color: #f0f0f0 !important;
//   font-size: 22px;
// }
// .fleetList {
//   background-color: #f0f0f0;
//   padding-top: 15px;
//   padding-bottom: 10px;
//   margin-bottom: 15px;
//   border-left: 13.67px solid transparent;
// }

// .fleetList:hover {
//   background-color: #ddedfd;
//   border-left: 13.67px solid #0c74da;
//   box-sizing: border-box;
// }
// .fleetImg {
//   margin-right: 20px;
//   margin-left: 10px;
//   max-width: 52px;
// }
// .fleetIcons {
//   padding: 0 5px;
// }
// .fleetText {
//   margin-bottom: 6px;
// }
// .nodatanotificationcontainer {
//   position: relative;
//   width: 100%;
//   z-index: 10;

//   #nodatanotification {
//     color: #fff;
//     background-color: #f03040;
//     border-color: #f03040;
//     padding: 0.5rem 1.25rem;
//     border-radius: 0px;
//     max-width: 600px;
//     margin: 0 auto;
//     position: absolute;
//     left: 0;
//     right: 0;
//     z-index: 3;
//     text-align: center;
//     top: 10px;
//     .nodatanotificationcontent {
//       color: #fff;
//       margin: 0px;
//       background: url("../../assets/images/error-white.svg") no-repeat 8px;
//     }
//     .close {
//       color: #fff;
//       padding: 0.5rem 1.25rem;
//       opacity: 1;
//     }
//   }
// }
// .disabledcontent {
//   opacity: 0.5;
//   pointer-events: none;
//   cursor: unset;
// }
// .disabledlink {
//   opacity: 0.5;
//   pointer-events: none;
//   cursor: unset;
// }
// @media only screen and (min-width: 280px) and (max-width: 767px) {
//   .FleetListmobile {
//     width: 33.33%;
//   }
// }

// @media only screen and (min-width: 280px) and (max-width: 1023px) {
//   .fleetListContainer {
//     .card-body .col-md-6 {
//       max-width: 100%;
//       flex: 0 0 100%;
//     }
//   }
// }

// @media only screen and (min-width: 1023px) and (max-width: 1115px) {
//   .fleetListContainer {
//     .card-body .col-md-6 .fleetImg {
//       margin-right: 10px;
//       margin-left: 0px;
//       max-width: 52px;
//     }
//   }
// }

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  cursor: pointer;
}

#loader {
  position: absolute;
  top: 55%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.nodatanotificationcontainer {
  max-width: 365px;
  width: 50%;
  position: absolute;
  right: 15px;
  z-index: 10;
  -webkit-box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.33);
  border: 1px solid #b4b4b4;
  background-color: #ffffff;
  margin-top: 10px;
}

#nodatanotification {
  border-radius: 0px;
  padding: 5px;
  margin-bottom: 0px;

  .close {
    padding: 0px;
    top: 7px;
    right: 15px;
    color: #464646;
  }
}
.nodatanotificationcontent {
  background-image: url("../../assets/images/Icon_info.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  background-size: 24px 24px;
  margin-bottom: 0px;
  line-height: 30px;
  margin-left: 5px;
  font-family: mediumFont;
  font-size: 15px;
}


