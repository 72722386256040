.containerminimizedsidemenu{
    background-color: #ffffff;
    //border: 1px solid #f5f5f5;
    width: 100%;
    min-height: 100vh;
    margin-right: 12px;
    padding-top: 25px;
    padding-bottom: 25px;
    //text-align: center;
    //float: right;
    text-align: -webkit-center;
    @media only screen and (max-width: 1022px) {
      -webkit-writing-mode: vertical-lr;
      width: auto;
      padding-top: 0px;
      min-height: 50%;
    }
  .labelstyle{
    font-size: 14px;
    font-family: normalFont;
    //line-height: 20px;
    color: #464646;
    width: 54px;
    height: 201px;
    //margin: 0px 10px 0px 10px;
    //margin-top: 20px;
    //padding-top: 125px;
    background-color: #ffffff;
    text-align: -webkit-center;
    // white-space: nowrap; 
  //width: 70px; 
  // overflow: hidden;
  // text-overflow: ellipsis;
  }
  .active{
    background-color: #DDEDFD;
    border-bottom: 5px solid #2E92FA;
  }
  .fleetlabel{
    //writing-mode:tb-rl;
    transform: rotate(180deg);
    //margin-top: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // width: 162px;
    height: 161px;
    writing-mode: vertical-rl;
	    line-height: 3;
    //padding-right: 10px;
    .fleeticon{
      //padding: 8px;
      transform: rotate(90deg);
      vertical-align: baseline;
    }
  }
 
  .vehiclelabel{
     //writing-mode:tb-rl;
     transform: rotate(180deg);
     //margin-top: 100px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     // width: 162px;
     height: 161px;
     writing-mode: vertical-rl;
       line-height: 3;
     //padding-right: 10px;
    .vehicleicon{
      //padding: 8px;
      transform: rotate(90deg);
      vertical-align: baseline;
    }
  }
    
  .productlabel{
     //writing-mode:tb-rl;
     transform: rotate(180deg);
     //margin-top: 100px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     // width: 162px;
     height: 161px;
     writing-mode: vertical-rl;
       line-height: 3;
     //padding-right: 10px;
    .producticon{
      //padding: 8px;
      transform: rotate(90deg);
      vertical-align: baseline;
    }
  }
  
//   .expandrighticon{
//     position: fixed;
//     top: 50%;
//     left: 2%;
//     z-index: 1;
//     cursor: pointer;
//     @media only screen and (max-width: 1022px) {
//       display: none;
//     }

//     // text-align: center;
//     // margin: 15px;

//     //margin: 31%;
//     // position: absolute;
//     // top: 50%;
//     // height: 10em;
//     // margin-top: 23em;
//     // margin-left: 20px;
// }  
.expandrighticon{
  position: fixed;
  top: 50%;
  z-index: 1;
  left: 0px;
  cursor: pointer;
  background: #ddedfd;
  padding: 5px;
  box-shadow: 3px 0 3px #ada5a5;
  border-radius: 0 5px 5px 0;
  z-index: 9999;
  @media only screen and (max-width: 1022px) {
    display: none;
  }

  // text-align: center;
  // margin: 15px;

  //margin: 31%;
  // position: absolute;
  // top: 50%;
  // height: 10em;
  // margin-top: 23em;
  // margin-left: 20px;
} 
}

