.innerFilterContainer {
  background-color: #fafafa;
  padding: 10px;
}

.date-tabs-container {
  border-top: 15px solid #f4f4f4;
  //margin-top: 16px !important;
  min-height: 45vh;
  background-color: #ffffff;
}
.detailViewRightinner {
  .borders {
    border: 1px solid #a0a0a0;
    width: 100%;
    height: 500px;
  }
}

#fleettabcon {
  min-height: 83vh;
}
// .fleet_view_event {
//   min-height: 100vh !important;
// }

.copypathdianotificationcontainer {
  max-width: 265px;
  width: 50%;
  position: absolute;
  right: 15px;
  top: 50px;
  z-index: 10;
  -webkit-box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.33);
  border: 1px solid #b4b4b4;
  background-color: #ffffff;
  margin-top: 10px;
  .copypathdianotificationcontent {
    background-image: url("../../assets/images/Icon_info.svg");
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 30px;
    background-size: 24px 24px;
    margin-bottom: 0px;
    line-height: 30px;
    margin-left: 5px;
    font-family: mediumFont;
    font-size: 15px;
  }
  #copypathnotification {
    border-radius: 0px;
    padding: 5px;
    margin-bottom: 0px;

    .close {
      padding: 0px;
      top: 7px;
      right: 15px;
      color: #464646;
    }
  }
}
.sideMenucon{
  overflow-y: scroll;
  max-height: 87vh;
}
.sideMenucon {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.sideMenucon::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}