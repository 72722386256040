#fleet-tabpane-Overview-vehicleprod-view .filtering {
  min-height: 340px;
}

.haveinnertabs #binfo {
  background-image: url("../../assets/images/binfo.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 20px;
}

.haveinnertabs {
  .active {
    #binfo {
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 20px;
      background-image: url("../../assets/images/binfo_hover.svg");
    }
  }
}
.availcon {
  th:nth-child(3),
  td:nth-child(6),
  th:nth-child(7) {
    display: none;
  }
}
.haveinnertabs .stsinfo .txt {
  font-family: mediumFont;
  font-size: 15px;
  margin-right: 4px;
}
.stsinfo {
  .util-info-smalltxt {
    font-family: normalFont;
    font-size: 12px;
    color: #000000;
  }
  .util-info-txt {
    font-family: normalFont;
    font-size: 14px;
    color: #000000;
  }
  .formulatext {
    //max-width: 300px;
    margin: 0px !important;
    hr {
      margin-top: 4px;
      margin-bottom: 4px;
      border-color: #000000;
    }
    .dividesymbol {
      font-family: boldFont;
      font-size: 20px;
      vertical-align: middle;
    }
  }
}

.threstable {
  width: 100%;
}
