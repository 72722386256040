.pagination {
  button {
    border: 0px solid #bababa;
    margin: 0 4px !important;
    &:hover {
      background: #ebebeb !important;
    }
    &:disabled {
      pointer-events: none;
    }
  }
  .pagecount {
    margin: 0 4px !important;
  }
}

.event-list-filter {
  .table-responsive {
    //max-height: 63vh;
    overflow-x: auto;
    //position: absolute;
    thead {
      position: sticky;
      top: 0;
      background-color: #ffffff;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    }
  }
  /**  STYLE 11 */
  .table-responsive::-webkit-scrollbar {
    //  width: 10px;
    background-color: #f5f5f5;
  }
  .table-responsive::-webkit-scrollbar-track {
    // border-radius: 10px;
    background: #f1f1f1;
    // border: 1px solid #ccc;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    //  border-radius: 10px;
    background: linear-gradient(left, #fff, #e4e4e4);
    border: 1px solid #aaa;
  }

  .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  .table-responsive::-webkit-scrollbar-thumb:active {
    background: linear-gradient(left, #a8a8a8, #a8a8a8);
  }
}

.tdbordertop {
  border-top: none !important;
}
.tdexpanded {
  text-align-last: left !important;
  vertical-align: top !important;
}
