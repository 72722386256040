.innerFilterContainer {
  background-color: #fafafa;
  padding: 10px;
}

.date-tabs-container {
  border: 1px solid transparent;
  //margin-top: 16px !important;
  //min-height: 70vh;
}

.detailViewRightinner {
  .borders {
    border: 1px solid #a0a0a0;
    width: 100%;
    height: 500px;
  }
}

.apiresnotification {
  border: 0;
  border-radius: 0;

  .toast-header {
    font-family: normalFont;
    background-color: #0ca919;
    color: #ffffff;
    font-size: 16px;
    height: 48px;

    img {
      padding-right: 5px;
    }

    .close {
      color: #ffffff;
      font-family: normalFont;
      font-size: 22px;
      font-weight: 500;
      line-height: 48px;
      text-shadow: none;
      opacity: 1;

      &:hover {
        color: #ffffff;
        font-family: normalFont;
        font-size: 22px;
        font-weight: 500;
        line-height: 48px;
        text-shadow: none;
        opacity: 1;
      }
    }
  }
}

#transp {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
  z-index: 1;

  #transpp {
    display: table-cell;
    vertical-align: middle;
    color: #0c74da;
    font-family: mediumFont;
    font-size: 24px;
  }
}

.btn-close {
  display: none !important;
}



.datepickervalue {
  background-image: url("../../assets/images/emailalert/datepicker.svg");
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  margin-right: 30px;
}