.broadcast_page {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 20px;
  justify-content: top;
  font-family: mediumFont;
  margin-top: 50px;
}

.broadcast_config {
  background-color: white;
  padding: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 60px;
}

.broadcast_input {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.broadcast_messages {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
}

.message_expanded {
  background-color: #f5f5f5;
  padding: 20px;
  width: 500px;
}

.message_text {
  font-family: ABBvoice;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  color: #525252;
}
.message_header_label {
  color: #696969;
  font-family: ABBvoice;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
}
.message_btn_rect {
  background-color: #dcdcdc !important;
  border-radius: 0px !important;
  border: 1px solid #c8c8c8 !important;
  font-family: ABBvoice;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1f1f1f;
  width: 100px;
  &:hover {
    background-color: white !important;
  }
}

.btn_count {
  width: 40px;
  margin: auto;
  border-radius: 40%;
  background-color: #00000016;
  padding: 2px 14px 2px 14px;
}

.broadcast_message_box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px 12px 10px 16px;
  margin-bottom: 10px;
  border-bottom: #bababa 1px solid;
}
.skewdiv{
  width: 10px;
  transform: skew(-14deg);
  margin-left: -5px;
}
