.designbook-gradientbar-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  }
.designbook-gradientbar-barwrapper{
width: 100%;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
}
.designbook-gradientbar{
background-color: black;
border-left: 2px solid black;
border-right: 2px solid black;
}
.designbook-gradientbar--primary{
 flex-grow: 1;
}
.designbook-gradientbar-footer{
  display: flex;
  flex-direction: row;
  
}