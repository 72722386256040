.overflowStyle {
    max-height: 500px;
    overflow-y: scroll;
  };
  .option-form::-webkit-scrollbar {
  width: 10px;
  //background-color: #f5f5f5;
}

.option-form::-webkit-scrollbar-track{
  //  border-radius: 10px;
  //background: rgba(0, 0, 0, 0.1);
  border: 0px solid #ccc;
}

.option-form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

.option-form::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.option-form::-webkit-scrollbar-thumb:active{
  background: linear-gradient(left, #a8a8a8, #a8a8a8);
}