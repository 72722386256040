.unauth_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  background-color: white;

  .unauth_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: large;
    font-weight: 500;
    font-family: mediumFont;
    color: #696969;
  }
}

.buttonBack {
  padding: 10px 20px;
  font-size: 16px;
  color: dimgrey;
  font-size: medium;
  font-weight: 400;
  font-family: mediumFont;
  color: #696969;
  display: flex;
  align-items: center;
}