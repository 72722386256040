.activebreadcrumb {
  color: #0c74da;
  text-decoration: none;
  &:hover {
    color: #2e92fa;
    text-decoration: none;
  }
  &:active {
    color: #2e92fa;
    text-decoration: none;
  }
}

.monitered-count {
  font-size: 18px;
  .boldText {
    padding: 0px 5px;
  }
}

.breadcrumbstyle {
  font-family: mediumFont;
}
.fixedCumb{
  position: fixed;
  z-index: 99;
}