.tabfleetsetupview {
  border: 1px solid #ebebeb;

  .tabicon {
    padding: 0px 12px 0px 0px;
    //width: 32px;
    //height: 32px;
    padding-right: 7px;
  }

  .tabtitle {
    color: #5a5a5a;
    font-size: 16px;
    font-family: "mediumFont";
    padding-right: 16px;
    padding-left: 16px;
  }
}
.productsetupusagestabs .nav-tabs{
    border-top: 0px solid #a0a0a0 !important;
}
.tabfleetsetupview .nav-tabs {
  border: 0px;
  border-top: 2px solid #a0a0a0;
  margin-bottom: 16px;
  // background-color: #ffffff;
  .nav-link {
    padding: 1px 0px;
    width: auto;
    text-align: center;
    max-width: 200px;
    border-color: transparent;
    border-width: 2px 0px 0px 0px;

    .tabtitle {
      color: #5a5a5a;
      border-left: 1px solid #bababa;
      display: block;
      height: 32px;
    }
    &:hover {
      border-color: #3366ff;
      border-width: 2px 0px 0px 0px;
      border-radius: unset;
      // background: #ffffff;
    }
  }
  .nav-link.active {
    border-color: #3366ff;
    border-width: 2px 0px 0px 0px;
    border-radius: unset;
    background: #ffffff;
    .tabtitle {
      //color: #2e92fa;
    }
    .tabIcon use,
    .tabIcon g {
      fill: #2e92fa;
    }
  }
}
.rw-widget-container {
  border-radius: 0;
}

.tabfleetviewmap .nav-tabs {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 7px 7px 7px 17px;
  .nav-link {
    border-color: transparent;
    border-width: 4px 0px 0px 0px;
    border-radius: unset;
    padding: 7px 15px;
    width: auto;
    max-width: 30%;
    text-align: center;
    &:hover {
      border-color: #abd3fd;
      border-width: 4px 0px 0px 0px;
      border-radius: unset;
    }
    .tabtitle {
      color: #5a5a5a;
      font-size: 14px;
      font-family: "mediumFont";
      line-height: 18px;
      text-align: center;
    }
    .tabIcon use,
    .tabIcon g {
      fill: #2e92fa;
    }
  }
  .nav-link.active {
    border-color: #2e92fa;
    border-width: 4px 0px 0px 0px;
    border-radius: unset;

    .tabtitle {
      //color: #2e92fa;
      color: #464646;
      font-size: 14px;
      font-family: "mediumFont";
      line-height: 18px;
      text-align: center;
    }
    .tabIcon use,
    .tabIcon g {
      fill: #2e92fa;
    }
  }
}

.tabmapcontainer {
  margin: 7px 7px 7px 17px;
}

.sliderlist {
  padding: 0px;
  cursor: pointer;
  border-bottom: 0px solid #ffff;
  background-color: #ffffff;
  //font-weight: 600;
  width: 100%;
  text-align: left;
  border-left: 6px solid transparent;
  // &:hover {
  //   border-left: 6px solid #0c74da;
  //   box-sizing: border-box;
  //   background-color: #ddedfd;
  // }
  .text {
    text-align: left !important;
  }
}

.sliderlistactive {
  border-left: 6px solid #0c74da;
  box-sizing: border-box;
  background-color: #ddedfd;
}

.sliderlistcontainer {
  margin-top: 20px;
  overflow: auto;
  width: 100%;
  max-height: 310px;
  min-height: 100px;
  border: 1px solid #e5e5e5;
}
.matrixIcon {
  background-color: transparent;
  cursor: pointer;
}

.tabfleetview .btn-link-custom {
  color: #0c74da;
}
.tabfleetview button:disabled {
  //color: #464646;
  opacity: 0.4;
}

#fleet-tab-Overview .tabtitle,
#fleet-tab-Overview-vehicle-view .tabtitle {
  border-left: 0px solid #ececec;
}
.overview-grey-info {
  background-color: #fafafa;
  min-height: 105px;
  text-align: center;
  padding: 0px;
  margin-right: 20px;
  max-width: 31.8%;
  flex: 0 0 31.8%;
  font-family: "mediumFont";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .overview-value {
    font-size: 20px;
  }
}
#last-tab {
  margin-right: 0px;
}
#fleet-tabpane-Overview .sub {
  font-family: "mediumFont";
  padding-bottom: 20px;
}
.dateupdated {
  margin-bottom: 20px;
}

#fleet-tabpane-Energy-throughput {
  .nav-tabs {
    max-width: 205px;
    margin: 20px auto;
  }
  .nav-link {
    width: 50%;
    min-height: 30px;
    border: 1px solid #c8c8c8;
    border-radius: 0;
    background-color: #dcdcdc;
    &:hover {
      background-color: #fafafa;
    }
  }

  .nav-link.active {
    background-color: #ddedfd;
    border: 1px solid #2e92fa;
    .tabtitle {
      color: #004c97;
    }
  }
  .tabtitle {
    font-size: 16px;
    padding: 0;
    border: 0;
    color: #464646;
  }
}

.main-tab-content {
  margin-bottom: 16px;
}
#fleet-tabpane-Overview {
  padding: 10px 20px;
}
#fleet-tabpane-Energy-throughput,
#fleet-tabpane-SoC-range-usage {
  padding: 0px 2%;
}
