.pagination {
    button {
      border: 0px solid #bababa;
      margin: 0 4px !important;
      &:hover {
        background: #ebebeb !important;
      }
      &:disabled {
        pointer-events: none;
      }
    }
    .pagecount {
      margin: 0 4px !important;
    }
  }
  
.arrowicon{
  padding: 5px 10px 5px 10px;
}
  