

%version-info-base-container {
  text-align: center;
  font-size: 12px;
  color: #464646;
  display: flex;
  align-items: center;
  justify-items: center;
  border-top: 1px solid #d2d2d2;
  font-family: lightFont;
  @media only screen and (min-width: 767px) {
    padding-top: 10px;
  }

  a:hover {
    background: none;
  }
  a:focus {
    color: #464646;
  }

  a {
    color: #464646;
    margin-left: 10px;
    margin-right: 10px;
    font-family: lightFont;
  }
}

.version-info {
  &-container {
    @extend %version-info-base-container;
  }

  &-container-mobile {
    @extend %version-info-base-container;
    font-size: 11px;
  }
}


.footer {

  width: 100%;
  background-color: #f5f5f5;
  z-index: 1;
}
.cookiesetting{
  cursor: pointer;
}