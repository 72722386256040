.customsynchedchart {
  //cursor: zoom-in;

  //margin-left: 18px;

  max-width: 100%;
  .highcharts-root {
    max-width: 100%;
    font-family: lightFont, Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    height: 100%;
  }
  .highcharts-legend-item {
    .highcharts-graph {
      stroke-width: 6px !important;
    }
  }

  .highcharts-button-box {
    fill: transparent;
  }
}
.highcharts-background {
  cursor: zoom-in;
}
.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.chart-style {
  // margin: auto;
  // display: block;
  vertical-align: middle;
}
// .highcharts-markers {
//   opacity: 0.6;
// }
.highcharts-series-hover {
  // opacity: 0.4;
}

// .highcharts-series-0:hover ~ .highcharts-series-1 {
//   opacity: 0.2;
// }
// .highcharts-series-1:hover ~ .highcharts-series-0 {
//   opacity: 0.2;
// }

.highcharts-axis-labels text,
.highcharts-tooltip text tspan {
  font-size: 12px !important;
  font-family: normalFont !important;
}

.highcharts-container .highcharts-title {
  font-size: 16px !important;
  font-family: "mediumFont" !important;
  width: 95% !important;
  border-bottom: 2px solid #1f1f1f;
  padding-bottom: 5px;
  display: "block";
  top: 3px !important;
}
// .highcharts-yaxis-grid .highcharts-grid-line:first-child {
//   stroke: #000;
//   stroke-width: 2;
// }

#fleet-tabpane-Condition-utilization-vehicleprod-view
  .highcharts-container
  .highcharts-title {
  width: 93% !important;
}

.highcharts-contextmenu {
  top: 20px !important;
  .highcharts-menu {
    box-shadow: none !important;
    border: 1px solid rgb(153, 153, 153) !important;
    background: rgb(255, 255, 255) !important;
    padding: 5px 0px !important;
    .highcharts-menu-item {
      font-size: 12px !important;
      font-family: normalFont !important;
    }
  }
}
