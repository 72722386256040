
.customStep {
    background-color: #fff;
    border: 1px solid #00000029;
    border-radius: 8px;
    margin: 0;
    text-align: left;
    font-size: 14px;
    color: #000000;
    line-height: 1.5;
}
.customStep .introjs-tooltip{
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
}
.customStep .introjs-tooltip-header{
    display: none;

}



.customButton{
    background-color: #3366FF;
    font-weight: bolder;
    color: white;
    border: none;
    border-radius: 100%;
    padding: 8px 16px;
    font-size: 16px;
    text-decoration: none;
}

.customButton:hover{
    background-color: #0a41c9;
    color: white;
}
.customStep .introjs-tooltipbuttons{
    display: flex;
   justify-content: right;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    border-top: 1px solid #00000029;
}

.introjs-helperLayer {
box-shadow: rgb(173 173 173 / 80%) 0px 0px 1px 2px, rgba(33, 33, 33, 0.25) 0px 0px 0px 5000px !important;
opacity: 1;
}

.introjs-prevbutton {
    display: none;
}