.overviewtitle {
  line-height: 30px;
  font-family: mediumFont;
  font-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(50% - 55px) 50%;
  background-size: 24px 24px;
  text-align: center;
}
.overviewsubtitle {
  font-family: normalFont;
  font-size: 12px;
  margin-bottom: 8px;
  text-align: center;
}
#ava {
  background-image: url("../../assets/images/ava_title.svg");
}
#rel {
  background-image: url("../../assets/images/rel_title.svg");
  background-position: calc(50% - 50px) 50%;
}
#str {
  background-image: url("../../assets/images/str_title.svg");
  background-position: calc(50% - 50px) 50%;
}
.piecontainer {
  max-width: 295px;
  // max-height: 360px;
  // background-color: #fafafa;
  margin: 0 auto;
}

#last-tab {
  .overviewtitle {
    padding-left: 8px;
    text-align: left;
  }
  .vlistcon {
    max-height: 45vh;
    overflow: auto;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 8px;
    .FleetListcontainer {
      padding: 8px;
      cursor: pointer;
      margin: 0 0 1px;
      background-color: #fff;
      width: 100%;
      text-align: left;
      border-left: 0px;
      font-family: mediumFont;
      font-size: 14px;
    }
    .FleetListcontainer:hover {
      border-left: 0;
      box-sizing: border-box;
      background-color: #ebebeb;
    }
    .FleetListicon {
      padding-right: 8px;
    }
    .hidewithoutspace {
      display: flex !important;
    }
    .conditionIcon {
      position: relative;
      box-sizing: content-box;
      width: 16px;
      height: 16px;
      background-size: contain;
      margin-right: 8px;
      background-repeat: no-repeat;
    }
    #ava_avai_sts {
      background-color: transparent;
      background-image: url("../../assets/images/ava_avai_sts.svg");
    }
    #ava_not_avai_sts {
      background-color: transparent;
      background-image: url("../../assets/images/ava_not_avai_sts.svg");
    }
    #ava_unknown_sts {
      background-color: transparent;
      background-image: url("../../assets/images/ava_unknown_sts.svg");
    }
    #rel_avai_sts {
      background-color: transparent;
      background-image: url("../../assets/images/rel_avai_sts.svg");
    }
    #rel_not_avai_sts {
      background-color: transparent;
      background-image: url("../../assets/images/rel_not_avai_sts.svg");
    }
    #rel_unknown_sts {
      background-color: transparent;
      background-image: url("../../assets/images/rel_unknown_sts.svg");
    }
    #str_avai_sts {
      background-color: transparent;
      background-image: url("../../assets/images/str_avai_sts.svg");
    }
    #str_not_avai_sts {
      background-color: transparent;
      background-image: url("../../assets/images/str_not_avai_sts.svg");
    }
    #str_unknown_sts {
      background-color: transparent;
      background-image: url("../../assets/images/str_unknown_sts.svg");
    }
    .graystar {
      font-size: 16px;
      color: #d2d2d2;
      line-height: unset;
      cursor: pointer;
      font-family: "normalFont";
    }
  }
}

.fleetoverview {
  max-width: 980px;
  // margin-left: 7% !important;
  .lightbg {
    padding-top: 16px;
  }
  .active-box {
    background: #f5f5f5;
  }
}

.lightbg {
  background: #fafafa;
}
.lightbg:hover {
  background: #f5f5f5;
}

#ava-con {
  .overviewsubtitle {
    margin-bottom: 26px;
  }
}
#rel-con {
  .overviewsubtitle {
    margin-bottom: 26px;
  }
}
// .fleetoverview .lightbg:nth-child(1) > .overviewsubtitle,
// .fleetoverview .lightbg:nth-child(2) > .overviewsubtitle {
//   margin-bottom: 25px !important;
// }
