/* Smartphones (portrait and landscape) ----------- */
$datepicker: url('../../assets/images/emailalert/datepicker.svg');
.error-message-time {
  color: red;
  font-family: mediumFont;
  font-weight: bold;
}

.smallButton[disabled] {
  background-color: #cccccc;
  border: 1px solid #cccccc;
}
.datePickerContainer .datePickerTextbox input {
  background-color: initial;
  font-size: 14px;
}

.selectmonitoringrange {
  max-width: 100%;
  margin-bottom: 15px;
}
.datePickerContainer .datePickerTextbox {
  max-width: 100%;
  //margin-bottom: 15px;
  .timevalue{
    border: 1px solid #d3d3d3;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    line-height: 28px;   
    .datepickervaluetimetrend{    
      width: 100%;
      min-height: 30px;
      border: none;  
      font-size: 13px;
      font-family: normalFont;
      background-image: $datepicker;
      background-repeat: no-repeat;
      background-position: right;     
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
.daterange-Backbtn {
  max-width: 26px;
  padding-right: 8px;
  .disabled {
    pointer-events: none;
    opacity: 0.5;
    button {
      color: #464646;
    }
  }
}
.daterange-Forwardbtn {
  max-width: 26px;
  padding-left: 8px;
  .disabled {
    pointer-events: none;
    opacity: 0.5;
    button {
      color: #464646;
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .selectmonitoringrange {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .datePickerContainer .datePickerTextbox {
    max-width: 92%;
    margin-bottom: 15px;
    .timevalue{
      border: 1px solid #d3d3d3;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 5px;    
      .datepickervaluetimetrend{    
        width: 100%;
        min-height: 34px;
        border: none;  
        padding-left: 10px;
        font-size: 13px;
        font-family: normalFont;
        background-image: $datepicker;
        background-repeat: no-repeat;
        background-position: right;     
        padding-right: 7px;
      }
    }
  }
  .datediff {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .selectmonitoringrange {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .datePickerContainer .datePickerTextbox {
    max-width: 48%;
    margin-bottom: 15px;
    .timevalue{
      border: 1px solid #d3d3d3;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 5px;    
      .datepickervaluetimetrend{    
        width: 100%;
        min-height: 34px;
        border: none;  
        // padding-left: 5px;
        font-size: 13px;
        font-family: normalFont;
        background-image: $datepicker;
        background-repeat: no-repeat;
        background-position: right;     
        padding-right: 20px;
      }
    }
  }
  .datediff {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .selectmonitoringrange {
    max-width: 100%;
    margin-bottom: 15px;
    flex: 0 0 100%;
  }
  .datePickerContainer .datePickerTextbox {
    max-width: 48%;
    margin-bottom: 15px;
    flex: 0 0 48%;
    .timevalue{
      border: 1px solid #d3d3d3;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 5px;    
      .datepickervaluetimetrend{    
        width: 100%;
        min-height: 34px;
        border: none;  
        // padding-left: 5px;
        font-size: 13px;
        font-family: normalFont;
        background-image: $datepicker;
        background-repeat: no-repeat;
        background-position: right;     
        padding-right: 20px;
      }
    }
  }
  .datediff {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles */
}
@media only screen and (min-width: 1024px) and (max-width: 1090px) {
  .datediff {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .datediff {
    flex: 0 0 31%;
    max-width: 31%;
  }
}
