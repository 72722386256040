.emailalertcontianer{
  background-color: #fafafa;
  margin-top: 50px !important;
  padding: 8px;
  .containertext{
    font-family: ABBvoice;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 28px;
    margin-left: 24px;
  }
  .containersearch{
    margin-top: 28px;
    // float: right;
    .alertsearch{
      float: right;
      border: 1px solid #D3D3D3;
      width: 255px;
      height: 32px;
      font-family: normalFont;
      font-size: 14px;
      padding: 5px;
    }
    .alltogglebtn{
      float: right;
      //display: inline-block;
      margin-left: 20px;
      margin-top: 5px;
      font-family: ABBvoice;
      font-size: 14px;
      line-height: 27px;
    }
  }
}