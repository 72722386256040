.error-component {
  /* stylelint-disable */
  min-height: 100px;
  background-color: transparent;
  display: table;
  width: 100%;
}

.error-content-div {
  width: 80%;
  height: 70%;
  background-color: white !important;
  display: table-cell;
  color: #000000;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  // top: 20%;
  h3 {
    font-family: lightFont !important;
    font-size: 16px;
  }
  button {
    font-family: lightFont;
    padding: 0px;
    color: #29abe2;
    font-size: 16px;
  }
}

// not allowed

body,
html {
  height: 100%;
  margin: 0;
}

.bgimg {
  height: 100%;
  background-position: center;
  background-size: cover;
  position: inherit;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 97%;
  text-align: center;
  img {
    width: 120px;
  }
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .notallowedtxt {
    font-size: 14px;
    font-family: "mediumFont";
  }
}

.notAllowedText {
  color: red;
}

.serviceNotAvailable {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 1px 0px 0px #00000033;
    .title {
      color: var(--font-light-primary, #1f1f1f);
      font-family: ABBvoice;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
    }
    .bar {
      margin-left: 30px;
      margin-right: 5px;
      font-size: x-large;
    }
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    padding: 5px;
    .message {
      margin-top: 50px;
      color: var(--text-black-secondary, #696969);
      text-align: center;
      font-family: ABBvoice;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 150% */
    }
    .refresh {
      display: flex;
      width: 120px;
      height: 45px;
      justify-content: center;
      align-items: center;
      background-color: #3366ff;
      border-radius: 28px;
      color: #ffffff;
      color: var(--text-white-primary, #fff);
      text-align: center;
      font-family: ABBvoice;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
    }
  }
}
