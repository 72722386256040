$checkboxcheck: url('../../assets/images/header/checkboxcheck.svg') no-repeat;

.cookieconfirmdialogbox{  
  // padding: 10px;  
  .modal-dialog {
    max-width: 90% !important;
    max-height: 90% !important;
  } 
  .modal-content {
    font-family: normalFont;
    font-size: 14px;
    border-radius: 0;
    border-top: 1px solid #ffffff;
  }
  .dialogboxcontainer{
    padding: 37px;
    line-height: 27px;
  .btncontainer{
    float: right;
    margin: 20px 0px 35px 0px;
    .okbtn1{
      color: black;
      font-family: mediumFont;
      font-size: 14px;
      border-radius: 20px;
      margin-right: 20px;
      width: 230px;
      padding: 8px;
      background-color: #ffffff;
      border-color: #acacac;
    }
    .okbtn2{
      font-family: mediumFont;
      font-size: 14px;
      border-radius: 20px;
      margin-right: 20px;
      width: 230px;
      padding: 8px;
      background-color: red;
      border: none;
    }
  }
  .headingcursor{
    background-color: #ff000f;
    margin: 0.625rem 0;
    width: 32px;
    height: 4px;
    margin-bottom: 12px;
  }
.headingtitle{
  font-family: mediumFont;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  max-width: 83.33%;  
  font-weight: 600;
}
.description{
  font-family: normalFont;
    font-size: 14px;
    line-height: 20px;
    //color: #0f0f0f;
    .link{
      border-bottom: 1px solid #000;
    }
}
.ckbx_description{
  margin-left: 27px;
}
.ckbx_cont{
  font-family: mediumFont;
    font-size: 14px;
    line-height: 20px;
    color: #0f0f0f;
    .ckbx{
      margin-right: 8px;
    }
    .ckbx_label{
      line-height: 2px;
    }    
    input[type="checkbox"] {
      // -webkit-appearance: none;
      // -moz-appearance: none;
      // appearance: none;
      height: 20px;
      width: 20px;      
      border: 1px solid #bbbbbb;      
      vertical-align: middle;
      &:checked {
        background-image: $checkboxcheck;
        background-position: center;
        background-repeat: no-repeat;        
      }
    }
}
}
}