.formfleetsetupview {
  font-family: "normalFont";
  font-size: 14px;
  .smallSilverButton,
  .smallButton {
    width: 110px;
  }
  .frmtitle {
    font-family: "mediumFont";
    border-bottom: 1px solid #bababa;
    padding: 16px;
  }
  .frmsection1 {
    background-color: #fff;
    padding: 8px 0;
    //border: 1px solid #a0a0a0;
    margin: 0;
    border-bottom: 0;
    clear: both;
    .frmcontrols {
      padding: 8px 16px;
      padding-bottom: 0;
      label {
        margin-bottom: 2px;
      }
      label:after {
        // content: "*";
        //color: red;
      }
      .form-control {
        border-radius: 0;
        max-height: 32px;
        font-family: "normalFont";
        font-size: 14px;
      }
      .form-control:focus {
        box-shadow: none;
        border-color: #ced4da;
      }
    }
  }
  .sitesetmapcon {
    padding: 16px;
    // border: 1px solid #a0a0a0;
    border-top: 0;
    padding-top: 0;
  }
  .smallSilverButton {
    margin-right: 16px;
  }
  .containertitlebar {
    margin-top: 10px;
    float: left;
  }
  .addName {
    display: inline-block;
    vertical-align: middle;

    .form-control {
      max-height: 30px;
      border-radius: 0;
    }
  }
  .rw-widget-container:focus {
    outline: 0px !important;
    box-shadow: none !important;
    border-color: #ced4da !important;
  }
  .rw-widget-container.rw-state-focus,
  .rw-state-focus > .rw-widget-container,
  .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container:hover {
    outline: 0px !important;
    box-shadow: none !important;
    border-color: #ced4da !important;
  }
  #rw_1_listbox > li:first-child,
  #rw_2_listbox > li:first-child,
  #rw_3_listbox > li:first-child {
    border: 0px !important;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
  .invalid-feedback {
    line-height: initial;
  }
  .form-control.is-valid,
  .was-validated .form-control:valid,
  .form-control.is-invalid,
  .form-control:invalid {
    background-image: none;
    border-color: #ced4da;
    padding-right: 5px;
  }
  .containertitlebar .icontrain {
    padding: 0px 0px 0px 8px;
  }

  .invalid-address {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .custom-search {
    .rbt-input-main {
      background-image: url("../../assets/images/search_16.svg") !important;
      // background-position: right center;
      background-repeat: no-repeat;
      background-size: 16px;
      font-size: 14px;
      background-position: right 10px center;
      padding-right: 60px;
    }
  }
  .rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    position: absolute;
    right: 25px;
    top: 0;
    width: 34px;
    .rbt-aux .rbt-close {
      margin-top: -4px;
      pointer-events: auto;
    }
    .rbt-close {
      z-index: 1;
    }
  }
  .sucessmsg {
    .savemsgalert {
      padding: 8px;
      margin-bottom: 8px;
    }
  }

  #maintenancesiteLoc {
    background-image: url("../../assets/images/search_16.svg") !important;
    // background-position: right center;
    background-repeat: no-repeat;
    background-size: 16px;
    font-size: 14px;
    background-position: right 10px center;
    padding-right: 60px;
  }
}
