#formprductsetup {
  font-family: "normalFont";
  font-size: 14px;
  display: block;

  .containertitlebar {
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      padding-left: 8px;
    }
  }

  .frmtitle {
    font-family: "mediumFont";
    border-bottom: 0px solid #bababa;
    padding: 0px 0px;
    position: relative;
  }

  .frmsubtitle {
    font-family: "mediumFont";
    border-bottom: 0px solid #bababa;
    padding: 0px 0px;
    font-size: 14px;
  }

  .frmtitlewithborder {
    // font-family: "mediumFont";
    border-bottom: 1px solid #bababa;
    margin: 0px 16px;
    padding-bottom: 8px;
  }

  .frmsection1 {
    background-color: #fff;
    padding: 8px 0;
    border: 0px solid #a0a0a0;
    margin: 0;
    border-bottom: 0;

    .frmcontrols {
      padding: 8px 16px;
      padding-bottom: 0;

      label {
        margin-bottom: 2px;
      }

      label:after {
        content: "*";
        color: red;
      }

      .optional:after {
        content: "";
      }

      .form-check-label:after {
        content: "";
      }

      .form-control {
        border-radius: 0;
      }
    }
  }

  .frmsectionlessborder {
    // background-color: #fafafa;
    border-top: 0;
  }

  .frmsection1withbg {
    background-color: #fafafa;
    margin: 8px 16px;
    padding: 8px 0px;
  }

  .productscon {
    background: #f0f0f0;
    margin: 16px;

    .frmtitlewithborder {
      padding: 8px 16px;
      margin: 0;
    }
  }

  .rw-widget-picker {
    max-height: 32px !important;
  }

  .rw-multiselect .rw-input-reset {
    height: calc(32px - 2px) !important;
  }

  .rw-multiselect-tag {
    margin-top: 0;
    height: auto;
  }

  .rw-i.rw-loading {
    background: url("../../assets/images/caretdown_16.svg") !important;
    width: 16px;
  }

  .rw-i-caret-down {
    height: 26px;
    line-height: 26px;
  }

  #savefleetdata {
    margin-right: 16px;
  }

  .addName {
    display: inline-block;
    vertical-align: middle;

    .form-control {
      max-height: 30px;
      border-radius: 0;
    }
  }

  .form-control:focus {
    box-shadow: none;
    border-color: #ced4da;
  }

  .custom-select {
    background-image: url("../../assets/images/caretdown_16.svg") !important;
    // background-position: right center;
    background-repeat: no-repeat;
    background-size: 16px;
    font-size: 14px;
  }

  #validdataPath {
    background-image: url("../../assets/images/valid.svg") !important;
  }

  #uncheckedPath {
    background-image: url("../../assets/images/unchecked.svg") !important;
  }

  .icontrain {
    padding: 0px 0px 0px 8px;
  }

  .form-check-input.is-valid~.form-check-label,
  .was-validated .form-check-input:valid~.form-check-label {
    color: initial;
  }

  .nav-link {
    border-color: transparent;
    border-width: 4px 0px 0px 0px;

    &:hover {
      border-color: #2e92fa;
      border-width: 4px 0px 0px 0px;
      border-radius: unset;
      background: none;
    }

    .tabtitle {
      color: #5a5a5a;
      border-left: 1px solid #bababa;
      display: block;
      margin-top: 5px;
      height: 22px;
    }
  }

  #setup-page-tab-fleetsetup-tab .tabtitle {
    border-left: 0px solid #ececec;
  }

  .nav-link.active {
    border-color: #2e92fa;
    border-width: 4px 0px 0px 0px;
    border-radius: unset;
    background: none;
  }

  .setup-tab-content {
    background: #ffffff;
    //height: 60vh;
    //max-height: 60vh;
    overflow: hidden;
    overflow-y: auto;

    // -ms-overflow-style: none; /* Internet Explorer 10+ */
    // scrollbar-width: none; /* Firefox */
    .prodType-select {
      height: 32px;
      border-radius: 0px;
    }

    .blank-label {
      height: 21px;
      display: block;
    }

    .addprodsetupbtn {
      background-image: url("../../assets/images/abb_plus-in-circle_24.svg");
      background-position: left 5px center;
      padding-left: 16px;
      background-repeat: no-repeat;
      width: 120px;
      font-family: "mediumFont";
      font-size: 14px;
      height: 32px;
    }

    .addprodsetupbtn:hover {
      color: #464646;
      background-color: #cbe4fe;
      border-color: #cbe4fe;
    }

    button[disabled] {
      pointer-events: none;
      color: rgba(70, 70, 70, 0.5);
      background-color: rgba(220, 220, 220, 0.4);
      border-color: rgba(220, 220, 220, 0.4);
    }

    .vehiclesection1 {
      padding: 24px 16px 16px 16px;
    }

    .vehiclesection2 {
      padding: 0px 16px 16px 16px;

      .frmcontrols {
        margin-top: 8px;
        padding-top: 4px;
        border-bottom: 1px solid #dedede;

        .input-group {
          padding-top: 15px;
        }

        input[type="text"] {
          border-radius: 0px;
          height: 32px;
          font-family: "normalFont";
          font-size: 14px;
        }

        input[type="number"] {
          border-radius: 0px;
          height: 32px;
          font-family: "normalFont";
          font-size: 14px;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      }

      .hidebotmborder {
        border-bottom: 0px !important;
      }

      .addbotmborder {
        border-bottom: 1px solid #dedede !important;
      }

      .firstitem {
        margin-top: 16px;
      }

      .forteenpx {
        font-size: 14px;
      }

      .twelvepx {
        font-size: 12px;
      }

      .removepadding {
        padding-top: 0px;
        padding-left: 0px;
      }

      .greybackground {
        background: #ebebeb;
        color: #000000;
        font-family: "normalFont";
        font-size: 14px;
      }

      .secteenpxtopmargin {
        margin-top: 16px;
      }
    }

    .prodsection1 {
      padding: 24px 16px 16px 16px;
    }

    .prodsection2 {

      // padding: 0px 16px 16px 16px;
      .frmcontrols {
        padding-top: 4px;
        border-bottom: 1px solid #dedede;

        input[type="text"] {
          border-radius: 0px;
          height: 32px;
          font-family: "normalFont";
          font-size: 14px;
        }

        input[type="number"] {
          border-radius: 0px;
          height: 32px;
          font-family: "normalFont";
          font-size: 14px;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      }

      .hidebotmborder {
        border-bottom: 0px !important;
      }

      .addbotmborder {
        border-bottom: 1px solid #dedede !important;
      }

      .firstitem {
        margin-top: 16px;
      }

      .forteenpx {
        font-size: 14px;
      }

      .twelvepx {
        font-size: 12px;
      }

      .removepadding {
        padding-top: 0px;
      }

      .greybackground {
        background: #ebebeb;
        color: #000000;
        font-family: "normalFont";
        font-size: 14px;
      }

      .secteenpxtopmargin {
        margin-top: 16px;
      }
    }
  }

  .setup-tab-content::-webkit-scrollbar {
    // display: none; /* Safari and Chrome */
  }

  .accordians-prodsetup {
    .accordion__button {
      padding: 8px 8px 8px 38px !important;
      background-image: url("../../assets/images/ESS 24.svg");
      background-repeat: no-repeat;
      background-position: left 5px center;
      font-size: 14px;
      font-family: "mediumFont";
      border-bottom: 2px solid #888888;
      background-color: #ffffff;

      &:hover {
        // border-left: 0px solid #0c74da;
        box-sizing: border-box;
        background-color: #ffffff;
      }

      &:focus {
        outline: none !important;
      }
    }

    .accordion__button:after {
      margin-top: 5px;
      font-size: 14px;
      color: #474747;
      content: url(../../assets/images/caretdown_16.svg) !important;
      margin-right: 10px;
      float: right;
    }

    .accordion__button[aria-expanded="true"]:after {
      content: url(../../assets/images/caretup_16.svg) !important;
    }

    .accordion__button:before {
      display: none !important;
    }

    [aria-expanded="true"] {
      box-sizing: border-box;
      background-color: #ffffff;
    }

    .accordion__panel {
      background: #ffffff;
      padding: 10px 5px;
    }
  }

  .norightborder {
    border-right: 0px;
  }

  .input-group-text {
    background-color: #fff;
    border-radius: 0px;
    border-left: 0px;
    color: #1f1f1f66;
    height: 32px;
  }
}

.productsetupusagestabs {

  // padding: 0px !important;
  .prodsetupusage-content {
    padding-top: 16px;
  }

  .tab-content {
    min-height: auto !important;
  }

  .tab-content {
    background: none;
    min-height: 490px;
  }

  .nav-tabs {
    border: 0px;
  }

  .nav-link {
    max-width: 220px !important;
    min-width: 126px !important;
    background-color: #eaeaea;
    padding: 0;
    min-height: 32px;
    background: #eaeaea;
    border-radius: 0 !important;
    box-sizing: border-box;
    width: auto;
    border: 1px solid transparent !important;

    &:hover {
      background-color: #ddedfd !important;
      border: 1px solid #62adfb !important;
    }

    .tabtitle {
      border-left: 1px solid #c8c8c899;
      margin-top: 0px !important;
      height: auto !important;
      font-size: 14px !important;
      line-height: 32px;
    }
  }

  .nav-link:nth-child(1) .tabtitle {
    border-left: 0px solid #c8c8c899 !important;
  }

  .nav-link.active {
    background-color: #ddedfd !important;
    border: 1px solid #62adfb !important;

    .tabtitle {
      color: #0c74da;
    }
  }
}

.validationfailed {
  color: #ef3934;
  font-size: 12px;
  text-align: right;
  // position: absolute;
  // top: 68px;
  // z-index: 1;
  max-width: 96%;
  margin-bottom: 0px;
}

#fleet-fields {
  padding: 24px 16px 16px 16px;

  .frmcontrols {
    padding: 0px !important;
    padding-bottom: 0;

    label:after {
      content: "" !important;
    }

    .centeritemcon {
      display: flex;
      align-items: center;

      #fleetName {
        display: inline;
        // max-width: 90%;
      }
    }

    #plusbtn {
      width: 32px;
      height: 32px;
      font-family: "normalFont";
      font-size: 29px;
      margin-right: 8px;
      margin-top: 22px;
      //  height: 32px;
    }

    .plusbtncon {
      max-width: 55px;
      padding: 0;
    }

    .updatebtncon {
      max-width: 115px;
      padding: 0;

      button {
        height: 32px;
        font-family: "normalFont";
        margin-left: 4px;
        margin-right: 0px;
        margin-top: 22px;
      }
    }
  }

  #validfleetdataPath {
    background-image: url("../../assets/images/valid.svg") !important;
    background-repeat: no-repeat;
    background-size: 16px;
    font-size: 14px;
    background-position: right 0.75rem center;
  }

  #invalidfleetdataPath {
    background-image: url("../../assets/images/invalid16.svg") !important;
    background-repeat: no-repeat;
    background-size: 16px;
    font-size: 14px;
    background-position: right 0.75rem center;
  }

  #uncheckedfleetPath {
    background-image: url("../../assets/images/unchecked.svg") !important;
    background-repeat: no-repeat;
    background-size: 16px;
    font-size: 14px;
    background-position: right 0.75rem center;
  }
}

.alreadyusedmsg {
  color: #ef3934;
  font-size: 12px;
  max-width: 96%;
  margin-bottom: 0px;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #464646;
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.oneline {
  display: flex;
  align-items: center;
}

.info-content {
  padding: 15px;
  border-left: 5px solid #0c74da;
  background: #eee;
  font-size: 12px;

}

.flextab {
  display: flex !important;
  align-items: center !important;
}

.productessconverter-tab {
  margin: 21px 0px 21px 15px;
  border-top: 0px !important;

  .nav-link {
    //max-width: 150px !important;
    min-width: 126px !important;
    background-color: #eaeaea;
    padding: 0px;
    min-height: 32px;
    background: #eaeaea;
    border-bottom: 1px solid #b8b8b8 !important;
    border-left: 1px solid #dedede !important;
    border-radius: 0px !important;
    box-sizing: border-box;
    width: auto;
    border-width: 1px 1px 1px 2px !important;

    &:hover {
      background-color: #ddedfd !important;
      border: 1px solid #62adfb !important;
    }
  }

  .nav-link.active {
    background-color: #ddedfd !important;
    border: 1px solid #62adfb !important;

    .tabtitle {
      color: #0c74da;
    }
  }

  .tabtitle {
    font-size: 14px !important;
    padding: 0;
    border: 0;
    color: #464646;
  }

  .tabIcon {
    line-height: 25px;
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 5px;
  }

  .active {
    .tabIcon {
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 5px;
    }
  }

  .tabIcon .tabtitle,
  .tabIcon img {
    display: inline !important;
    vertical-align: middle;
    border: 0px !important;
  }
}

.form-check-reverse {
  text-align: left;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 50px;
}

#contractor_status .form-switch .form-check-input {
  width: 3em;
  height: 1.3em;
}