.pagination {
  font-family: ABBvoice;
  font-size: 14px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .leftSide {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .rightSide {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  button {
    border: 0px solid #fff;
    margin: 0 4px !important;
    background: #fff;

    &:hover {
      background: #ebebeb !important;
    }

    &:disabled {
      pointer-events: none;
    }
  }

  .pagecount {
    margin: 0 4px !important;
    display: inline-flex;
  }
}

.event-list-filter {
  .table-responsive {
    //max-height: 100vh;
    min-height: 70vh;
    overflow: auto;

    //position: absolute;
    thead {
      position: sticky;
      top: 0;
      background-color: #ffffff;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    }
  }

  /**  STYLE 11 */
  .table-responsive::-webkit-scrollbar {
    //  width: 10px;
    background-color: #f5f5f5;
  }

  .table-responsive::-webkit-scrollbar-track {
    // border-radius: 10px;
    background: #f1f1f1;
    // border: 1px solid #ccc;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    //  border-radius: 10px;
    background: linear-gradient(left, #fff, #e4e4e4);
    border: 1px solid #aaa;
  }

  .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  .table-responsive::-webkit-scrollbar-thumb:active {
    background: linear-gradient(left, #a8a8a8, #a8a8a8);
  }
}

.tdbordertop {
  border-top: none !important;
}

.tdexpanded {
  text-align-last: left !important;
  vertical-align: top !important;
}

.filterrow {
  background-color: #f5f5f5;

  .sidemenufilterdropdown {
    box-sizing: border-box;
    font-size: 14px;
    font-family: ABBvoice;
    min-width: 160px;
  }

  .sidemenufilterdropdown .rw-list-option {
    padding: 5px;
  }

  .sidemenufilterdropdown .rw-list-option.rw-state-selected,
  .sidemenufilterdropdown .rw-list-option.rw-state-selected:hover {
    background-color: #2e92fa;
    border-color: #2e92fa;
    color: white;
  }

  .rw_reason_input {
    .rw-popup-container {
      width: 235px !important;
    }
  }
}

.reset-fill {
  display: none;
}
.activeButton{
  font-weight: 600;
  border-bottom: 2px solid #2E92FA !important;
  }
  .pageInput{
    width: 40px;
    border: 0;
    border-bottom: 1px solid #e3e0e0;
  }