.emergency_page {
  //width: 98vw;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: top;
  height: 82vh;
  font-family: mediumFont;
  margin-top: 50px;
}
.emergency_title {
  font-size: x-large;
}

.emergency_form {
  display: flex;
  flex-direction: column;
  .emergency_label {
    font-size: medium;
    margin-top: 20px;
    position: relative;
  }
  // border: 5px solid red;
}

.emergency_input {
  height: 40px;
  line-height: 40px;
  font-size: large;
}
.emergency_btn {
  width: 120px;
  height: 36px;
  background-color: orange;
  color: white;
  font-size: large;
  &:hover {
    background-color: rgb(248, 185, 70) !important;
  }
}
.emergency_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 25px;
}

.em_warning {
  color: red;
}

.change_btn {
  width: 120px;
  height: 36px;
  background-color: #2e92fa;
  color: white;
  font-size: large;
  &:hover {
    background-color: #56a3f5 !important;
  }
}
.aestrick {
  color: red;
  font-weight: 400;
}
.redInfo {
  font-size: 9px;
  font-style: italic;
  color: #646363;
  margin: 0;
}
