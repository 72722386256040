.productsetupusagestabs {
    // padding: 0px !important;
    .prodsetupusage-content {
      padding-top: 16px;
    }
    .tab-content {
      min-height: auto !important;
    }
    .tab-content {
      background: none;
      min-height: 490px;
    }
    .nav-tabs {
      border: 0px;
    }
    .nav-link {
      max-width: 220px !important;
      min-width: 126px !important;
      background-color: #eaeaea;
      padding: 0px 16px 0px 16px !important;
      min-height: 32px;
      background: #eaeaea;
      border-radius: 0 !important;
      box-sizing: border-box;
      width: auto;
      border-left: 1px solid #c8c8c899 !important;
        margin-top: 0px !important;
        height: auto !important;
        font-size: 14px !important;
        line-height: 32px;
        color: #5a5a5a !important;
        font-family: "mediumFont" !important;
      &:hover {
        background-color: #ddedfd !important;
        border: 1px solid #62adfb !important;
      }
    }
    .nav-link:nth-child(1) {
      border-left: 1px solid transparent !important;
    }
    .nav-link.active {
      background-color: #ddedfd !important;
      border: 1px solid #62adfb !important;
      .tabtitle {
        color: #0c74da;
      }
    }
  }
  span.tab.title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.switch-label{
  font-family: ABBvoice;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
color: var(--Global-black-primary, #000);


}
