.emailalertdialogbox{  
  .modal-dialog {
    max-width: 35% !important;
  } 
  .modal-content {
    font-family: normalFont;
    font-size: 14px;
    border-radius: 0;
    border-top: 3px solid #21A67A;
  }
  .okbtn{
    font-family: mediumFont;
    font-size: 14px;
    border-radius: 0px;
    margin-right: 20px;
    width: 75px;
  }
}