$datepicker: url('../../assets/images/emailalert/datepicker.svg');

.emailalertsetting{
  margin-top: 10px;
  .namedropdown{
    font-size: 13px;
    font-family: normalFont;
  }
  .alerttypedropdown{
    font-size: 13px;
    font-family: normalFont;
    // padding-top: 7px;
    z-index: 1;
    li.rw-multiselect-tag {
      height: auto !important;
      margin-bottom: 1px;
      border: 1px solid #d3e9fe;
      border-radius: 0px;
    }
    .rw-multiselect-tag{
      background-color: #e4f1fe;

    }
    .rw-multiselect-taglist {
      display: inline !important;
    }    
    .rw-widget-container {
      height: auto !important;  
    }        
    .rw-i-caret-down { 
      height: auto;  
      line-height: initial;  
    }  
    .rw-list-option.rw-state-focus {  
      border: 0px;  
    }
  }
  .alertfreqdropdown{
    font-size: 13px;
    font-family: normalFont;
  }
  .additionalinfo{
    width: 100%;
    min-height: 37px;
    border: 1px solid #d3d3d3;
    padding-left: 5px;
    font-size: 13px;
    font-family: normalFont;
  }
  .timevalue{
    border: 1px solid #d3d3d3;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;    
    .datepickervalue{    
      width: 100%;
      min-height: 34px;
      border: none;  
      // padding-left: 5px;
      font-size: 13px;
      font-family: normalFont;
      background-image: $datepicker;
      background-repeat: no-repeat;
      background-position: right;     
      padding-right: 20px;
    }
  // .emailalertdaterangepicker{
  //   min-height: 37px;
  //   border: 1px solid #d3d3d3;
  //   border-left: none;
  //   padding-right: 1px;
  // }
  }
  .actionbtn{
    // height: 30px;
    font-family: mediumFont;
    font-size: 14px;
    border-radius: 0px;
    min-height: 35px;
    // max-width: 68px;
    // width: auto;
    // padding: 0 8px;
    // margin-left: 8px;
  }

    .togglebtn{
      display: inline-block;
      //padding-left: 42px;
      margin-left: 5px;
    }
    .table {
      th:nth-child(1) {
        width: 206px;
      }
      th:nth-child(2) {
        width: 176px;
      }
      th:nth-child(3) {
        width: 380px;
      }
      th:nth-child(4) {
        width: 134px;
      }
      th:nth-child(5) {
        width: 120px;
      }
      th:nth-child(6) {
        width: 178px;
      }
      th:nth-child(7),
      td:nth-child(7) {
        display: none;
      }
    }
    .table-responsive {
      max-height: 100vh;
      min-height: 70vh;
      overflow: auto;
      thead {
        position: sticky;
        top: 0;
        background-color: #ffffff;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
      }
    }
}
