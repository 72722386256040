.emailalertpagination {
  button {
    border: 1px solid #bababa !important;
    margin: 0 4px !important;
    &:hover {
      background: #ebebeb !important;
    }
    &:disabled {
      pointer-events: none;
    }
  }
  .pagecount {
    margin: 0 4px !important;
  }
}

// .event-list-filter {
//   .table-responsive {
//     max-height: 100vh;
//     min-height: 70vh;
//     overflow: auto;
//     thead {
//       position: sticky;
//       top: 0;
//       background-color: #ffffff;
//       filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
//     }
//   }
//   /**  STYLE 11 */
//   .table-responsive::-webkit-scrollbar {
//     //  width: 10px;
//     background-color: #f5f5f5;
//   }
//   .table-responsive::-webkit-scrollbar-track {
//     // border-radius: 10px;
//     background: #f1f1f1;
//     // border: 1px solid #ccc;
//   }

//   .table-responsive::-webkit-scrollbar-thumb {
//     //  border-radius: 10px;
//     background: linear-gradient(left, #fff, #e4e4e4);
//     border: 1px solid #aaa;
//   }

//   .table-responsive::-webkit-scrollbar-thumb:hover {
//     background: #fff;
//   }

//   .table-responsive::-webkit-scrollbar-thumb:active {
//     background: linear-gradient(left, #a8a8a8, #a8a8a8);
//   }
// }

// .tdbordertop {
//   border-top: none !important;
// }
// .tdexpanded {
//   text-align-last: left !important;
//   vertical-align: top !important;
// }

.emailalertfilterrow {
  // background-color: #f5f5f5;
  // .sidemenufilterdropdown {
  //   box-sizing: border-box;
  //   font-size: 14px;
  //   font-family: normalFont;
  // }
  // .sidemenufilterdropdown .rw-list-option {
  //   padding: 5px;
  // }
  // .sidemenufilterdropdown .rw-list-option.rw-state-selected,
  // .sidemenufilterdropdown .rw-list-option.rw-state-selected:hover {
  //   background-color: #2e92fa;
  //   border-color: #2e92fa;
  //   color: white;
  // }
  // .rw_reason_input {
  //   .rw-popup-container {
  //     width: 235px !important;
  //   }
  // }
}

// .reset-fill {
//   display: none;
// }
