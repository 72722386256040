
.help {
    width: 0px;
    margin: 0 auto;
    text-align:center;
    right:10px;
    height:12px;
    z-index: 9;
    top:5px;
    position: absolute;
    //margin-left: 20px;
    padding: 0px 20px;
  }

  
  .help .question {
    height: 15px;
    width: 15px;
    font-size: 12px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    border-radius: 50%;
    border: 0px solid #333;
    cursor: pointer;
    position: relative;
    background: #777;
    color: #fff;
}
  
  