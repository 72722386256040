.lock {
  background: #fff;
  border-radius: 3px;
  width: 15px;
  height: 13px;
  margin-top: 6px;
  position: relative;
  animation: pulse 1s infinite;
  margin-left: 5px;
  margin-right: 5px;
}

.border {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 20px;
  padding: 15px 10px 15px 5px;
  border-radius: 33px;
  background: orange;
  color: #fff;
  align-items: center;
  font-weight: 600;
  font-family: ABBvoice;
  font-size:small;
}

.lock:before {
  content: "";
  display: block;
  position: absolute;
  border: 3px solid #fff;
  top: -7px;
  left: 0px;
  left: 1px;
  left: 1.5px;
  width: 12px;
  height: 12px;
  border-radius: 35px 35px 0 0;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}