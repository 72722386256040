$primary-color: #000;
$secondary-color: #fff;

.glossaryOverlay {

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: $secondary-color;
}

.glossaryHeading {
    font-size: 4em;
    color: $primary-color;
    font-weight: bolder;
    padding: 0px 0px;
}

.glossaryContainer {
    background: $secondary-color;
    width: 100%;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin-top: 100px;
}

.glossaryHeader {
    color: $secondary-color;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.glossaryContent {
    padding: 16px;
    flex-grow: 1;
    font-size: 16px;
    border: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: $primary-color;
    cursor: pointer;
    position: sticky;
    // top: 100px;
    margin-right: 16px;
    display: flex;
    justify-content: flex-end;
    z-index: 99;
}

.alphabet-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 16px;
    font-weight: bold;
}

.alphabet-letter {
    margin: 2px;
    padding: 2px;
    cursor: pointer;
    color: $primary-color;
    border-bottom: .0725rem solid $primary-color;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3px;
    height: 30px;
    transition: border-bottom-width 0.4s ease-in-out;
}

.alphabet-letter:hover {
    border-bottom: 3.05px solid $primary-color;
}

.glossary-items {
    padding: 16px;
}

.glossary-section {
    margin-bottom: 24px;
    color: #464646;
}

.back-to-top-button {
    border-bottom: .0725rem solid $primary-color;
    background: none;
    font-weight: bold;
    font-size: 14px;
    color: $primary-color;
}



.redUnderline {
    border-bottom: 8px solid red;
    width: 6%;
    padding: 20px;
    display: block;
    margin-left: 16px;
}

.redUnderlineSmall {
    border-bottom: 4px solid red;
    width: 28px;
    padding: 6px 12px;
    display: block;
}

strong {
    font-family: ABBvoice-Bold, sans-serif;
    font-weight: bolder;
}

.glossaryContainer {
    opacity: 0;
    transform: translate(0px, 200px);
    animation: slideIn 1s ease-in-out forwards;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translate(0);
    }
}

.glossary-items .glossary-section {
    animation-delay: 0.5s;
}

.glossary-section h2 {
    font-weight: bold;
    color: $primary-color;
}

.backtotopdiv {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    position: sticky;
    bottom: 2px;
    background: transparent;
}

.backToTop {
    display: table;
    background: rgb(218 215 215 / 57%);
    width: 40px;
    padding: 12px;
    border-radius: 50%;
    will-change: bottom, opacity;
    margin-left: 20px;
    left: 0;
    transform: translate(0);
    cursor: pointer;
}

// .backtotopdiv .back-to-top-button {
//     opacity: 1;
//     transform: translate(0);
// }

.glossaryContainer::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

.glossaryContainer::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f1f1f1;
    // border: 1px solid #ccc;
}

.glossaryContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(left, $secondary-color, #e4e4e4);
    border: 1px solid #aaa;
}

.glossaryContainer::-webkit-scrollbar-thumb:hover {
    background: $secondary-color;
}

.glossaryContainer::-webkit-scrollbar-thumb:active {
    background: linear-gradient(left, #a8a8a8, #a8a8a8);
}