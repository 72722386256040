#heatmap .highcharts-title{
    /* box-shadow: -70px -90px 0 90px #fff!important; */
  /* z-index: -1; */
  /* box-shadow: 11px -8px 3px #fff; */
  left: 5px !important;
  width: 99% !important; 
  text-align: left !important;
  border-bottom: 2px solid #333;
  color: #000;
  top: 61px !important;
  
  }
  #heatmap .highcharts-axis-labels.highcharts-yaxis-labels text[text-anchor="middle"] {
    fill: #565656 !important;  
    font-weight: 900 !important;
    /* writing-mode: vertical-lr; */
    transform: translate(0%, 0px); 
    transform-origin: center;
     text-orientation: mixed; 
     text-align: center;
     transition: all 0.3s ease;
     display: flex;
     align-items: center;
  }
  #heatmap .highcharts-axis-labels.highcharts-xaxis-labels text[text-anchor="middle"]{
    border-right: 2px solid #333 !important;
  }
  
  
  #heatmap .highcharts-axis-labels.highcharts-xaxis-labels{
   position: absolute;
   z-index: 99999 !important;
   color: #fff !important;
   transform: translate(0px, 0px);
  }
  #heatmap{
   width: 90%;
   background-color: #fff;
  }
  #heatmap .highcharts-grid-line,#heatmap .highcharts-axis-line,#heatmap .highcharts-axis-title {
    display: none;
  }
  
  #heatmap .highcharts-scrolling::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(101, 99, 99, 0.3);
      background-color: #F5F5F5;
  }
  
  #heatmap .highcharts-scrolling::-webkit-scrollbar
  {
      width: 8px;
      background-color: #F5F5F5;
  }
  
  #heatmap .highcharts-scrolling::-webkit-scrollbar-thumb
  {
      -webkit-box-shadow: inset 0 0 6px rgba(66, 65, 65, 0.3);
  }
  
  #heatmap .highcharts-mask{
  display: none;
  }
  span.newTitle {
    z-index: 9999;
    position: absolute;
    left: 0;
  }