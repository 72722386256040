/* Search */
.searchall{
box-sizing: border-box;
/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
height: 32px;
/* Grey/30 */
border: 1px solid #BABABA;
border-radius: 0px;;
/* Inside auto layout */
}


  #searchbox {
    background-image: url("../../assets/images/search_16.svg") !important;
    // background-position: right center;
    background-repeat: no-repeat;
    background-size: 16px;
    font-size: 14px;
    background-position: right 10px center;
    padding-right: 35px;
    flex-grow: 8;
  }

  #filterbtn{
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 4px;

position: absolute;
width: 32px;
height: 32px;

background-color: #DCDCDC99;
border: 1px solid #C8C8C899;
box-shadow: inset 0px -1px 0px #A0A0A0;
  }