.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  transition: all 3s;

}
.overlay-content {
  position: relative;
  max-width: 700px;
  width: auto;
  background-color: #fff;
  padding: 20px;
  border-top: 4px solid #000;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, .35);
  min-width: 400px;
  cursor: move;
  font-family: ABBvoice;
  font-size: 14px;
  font-weight: 500;
}

#overlayBox .submit-button {
  padding: 6px;
  color: #fff;
  background: #0C74DA;
  font-family: 'ABBvoice';
  font-style: normal;
  font-weight: 500;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid rgba(12, 116, 218, 0.15);
}

.close-button {
  top: 18px;
  right: 10px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #000;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute
}

.option-form {
  display: flex;
  flex-direction: column;

}

.option-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.option-list-item {
  border-left: 4px solid #fff;
  margin: 5px 0;
  background-color: #fff;
  cursor: pointer;
  height: 36px;
  line-height: 36px;
 
}

.option-list-item:hover {
  margin: 5px 0;
  background-color: #DDEDFD;
  cursor: pointer;
  color: #444;
  border-left: 4px solid #0c74da;

}

.option-list-item.selected {
  background-color: #ddedfd;
  cursor: pointer;
  color: #000;
  border-left: 4px solid #0c74da;
}

.option-input {
  display: none;
}

.option-label {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.option-text {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 800;
}

.option-text:hover {
  cursor: pointer;
}
#overlayBox .overlay-heading {
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
}
.top_head{
  display: flex;
  justify-content: space-between;
}
.buttonBox{
  display: flex;
  justify-content: flex-end;
}
.internal-head, .leftColumn{
  display: flex;
  flex-direction: column;
}
.linearList{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  margin-right: 10px;
}
.linearList:hover{
  cursor: pointer;
}
.secondaryfont{
  font-size: 12px;
}