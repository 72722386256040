.containertitlebarv3 {
    background-color: #ddedfd;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 16px;
    .icontrain {
      margin: 0px 0px 0px 13px;
    }
    .iconreport {
      float: right;
      padding: 12px;
    }
    .text {
      color: #004c97;
      font-family: ABBvoice;
      font-size: 18px;
      font-weight: 600;
      vertical-align: middle;
      //padding: 11px 0px 9px 0px;
      //line-height: 50px;
    }
    .iconconfig {
      float: right;
      padding: 12px 0px 0px 0px;
    }
    .menu {
      float: right;
      padding: 12px 16px 0px 27px;
    }
    .iconCollapse {
      float: right;
      margin-right: -15px;
      width: 40px;
      text-align: center;
      &:hover {
        background-color: #9ebff7;
      }
    }
    .iconbell {
      float: right;
      margin: 12px;
      &:hover {
        background-color: #9ebff7;
      }
    }
  }
  
  .fleetinfoborder .mediumText {
    padding-right: 5px;
    padding-left:0 ;
  }
        .fleetinfoborder .sub {
          padding-left: 0;
        }
  
  .fleetinfoborder .vehicleinfo:first-child {
    margin-right: 0px;
  }  
  .overallText {
    margin-right: 10px;
    .conditioncount {
      font-size: 16px;
      font-family: mediumFont;
    }
  }
  .overallText > .conditionIcon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-left: 5px;
  }
  