.custom-tab-content {
    padding: 0px 2%;
}

.first-tab-content {
    padding: 0px 2%;
}

#vehicle-products-tab .nav-link {


    .tabtitle {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

#vehicle-products-tab .active {
    max-width: none;
}

#fleet-tab-Overview-vehicleprod-view .tabtitle {
    border-left: 0px solid #ececec;
}

#fleet-tabpane-Condition-utilization-vehicleprod-view {
    padding-bottom: 16px;
}


.lock-zoom-con {
    padding: 0 20px;
    margin-top: 16px;

    .boldText {
        //font-weight: 600;
        font-family: mediumFont;
    }

    .visible {
        display: inline-block;
    }

    .zoomed-text {
        max-width: 69%;
        text-align: left;
        overflow-wrap: break-word;
        border: 1px solid rgba(200, 200, 200, 0.8);
        background-color: rgba(0, 0, 0, 0.04);
        color: #464646;
        font-size: 14px;
        padding: 8px;
        margin-left: 5px;

        .small-space {
            padding: 4px;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 1500px) {
        .zoomed-text {
            max-width: 100%;
            text-align: left;
            overflow-wrap: break-word;
            //display: block;
        }
    }

    .zoomed-btns {
        vertical-align: middle;

        .btn-link-custom {
            background-repeat: no-repeat;
            background-position: left 5px center;
            padding-left: 27px;
            background-size: 16px 16px;
            margin-left: 10px;

            &:hover {
                background-repeat: no-repeat;
                background-position: left 5px center;
                background-size: 16px 16px;
                background-color: #daedff !important;
                border: 1px solid #0c74da !important;
            }
        }
    }
}

#resetzoom {
    //background-image: url("../../../assets/images/abb_refresh_16.svg");
    background-image: url("../../assets/images/reset.svg");
}

#backzoom {
    background-image: url("../../assets/images/back_16.svg");
}

.hidden {
    display: none;
}

.clickdragtext {
    .drag-msg {
        max-width: 368px;
        padding-left: 32px;
    }
}

.timetrendchart {
    padding-left: 20px;
    padding-right: 15px;
}

#overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 20;
    cursor: pointer;
}

#loader {
    position: absolute;
    top: 55%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.haveinnertabs {
    .pagination {
        display: none;
    }

    .tab-content {
        padding: 16px;
        //background-color: #fafafa;
        margin: 16px 0px;
        overflow-x: hidden;
    }

    .nav-tabs {
        // max-width: 205px;
        // margin: 20px auto;
    }

    .nav-link {
        //max-width: 150px !important;
        min-width: 126px !important;
        background-color: #eaeaea;
        padding: 0px;
        min-height: 32px;
        background: #eaeaea;
        border-bottom: 1px solid #b8b8b8 !important;
        border-left: 1px solid #dedede !important;
        border-radius: 0px !important;
        box-sizing: border-box;
        width: auto;
        border-width: 1px 1px 1px 2px !important;

        &:hover {
            background-color: #ddedfd !important;
            border: 1px solid #62adfb !important;
        }
    }

    .nav-link.active {
        background-color: #ddedfd !important;
        border: 1px solid #62adfb !important;

        .tabtitle {
            color: #0c74da;
        }
    }

    .tabtitle {
        font-size: 14px;
        padding: 0;
        border: 0;
        color: #464646;
    }

    .tabIcon {
        line-height: 25px;
    }

    .tabIcon .tabtitle,
    .tabIcon img {
        display: inline !important;
        vertical-align: middle;
        border: 0px !important;
    }

    .stsinfo {
        margin-bottom: 16px;
        border-bottom: 2px solid #3e3e3e;
        padding-bottom: 8px;

        .txt {
            font-family: mediumFont;
            font-size: 16px;
            margin-right: 4px;
        }

        #ok {
            color: #007a33;
        }

        #notok {
            color: #f03040;
        }

        #unknown {
            color: #9f9f9f;
        }
    }

    #prodststabs-tabpane-avail .stsinfo {
        border-bottom: 0px;
    }

    .table thead th {
        border-top: 0px;
        border-bottom: 2px solid #afafaf;
    }
}

.event_minheight {
    min-height: 100vh;
}