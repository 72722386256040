#dasboardmap .gm-style .gm-style-iw-t::after {
  height: 15px;
  transform: translate(23%, -3%) rotate(46deg) !important;
  width: 15px;
  left: 12px;
}

#dasboardmap .gm-style .gm-style-iw-c {
  top: -67px;
  transform: rotate(0deg) !important;
  padding: 0px;
  left: 22px;
  border-radius: 0;
  // border-top: 4px solid #222121;
  min-width: 200px;
}

#dasboardmap .gm-style .gm-style-iw-d {
  overflow: initial !important;
}

#dasboardmap button.gm-ui-hover-effect {
  display: none !important;
}

.markerinfocontainer {
  height: 100%;
  width: 200px;
  max-height: 250px;
  max-width: 250px;
  overflow: auto;
  padding: 5px 10px;
}

hr.fleetListDashboardMapDivider {
  margin: 5px 0px;
  border-top: 1px solid #a0a0a0;
}

.siteNameDashbordMap {
  font-size: 16px;
  color: #464646;
  font-family: boldFont;
}

.fleetCountDashboadMap {
  font-size: 14px;
  margin: 5px 0px;
  font-family: boldFont;
}
.fleetNameDashboardMap {
  font-size: 12px;
  margin: 5px 0px;
  font-family: normalFont;
}
.checkedMarkDashboardMap {
  // width: 24px;
  margin-right: 5px;
  vertical-align: middle;
}
.infoCard {
  max-height: 150px;
  // height: 5px;
  // width: 15px;
}
