.disabled {
  opacity: 0.5;
}

.custom-tab-content {
  padding: 0px 2%;
}
.info-text {
  background-image: url("../../assets/images/abb_information.svg");
  background-repeat: no-repeat;
  padding-left: 35px;
  background-position: left;
  line-height: 24px;
}
