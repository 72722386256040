.innerFilterContainer {
  background-color: #fafafa;
  padding: 10px;
}

.date-tabs-container {
  border-top: 15px solid #f4f4f4;
  // border: 1px solid transparent;
  // margin-top: 20px;
  min-height: 45vh;
  background-color: #ffffff;
}

.batteryview .detailViewRightinner {
  overflow: visible;
}

.vehicle_product_event{
  min-height: 100vh !important;
}