.eventsetupform {
  font-family: normalFont;
  font-size: 14px;
  .frmsection1 .frmcontrols .form-control {
    border-radius: 0;
    max-height: 32px;
    font-family: normalFont;
    font-size: 14px;
  }
  .frmcontrols label {
    margin-bottom: 2px;
  }
  #comments {
    max-height: 64px;
  }
  .modal-footer {
    padding: 1rem 0;
    border: 0;
  }
  .form-control:read-only {
    pointer-events: none;
  }
  .topinfo {
    padding: 0px 8px;
    word-break: break-word;
  }
}
.modal-header {
  padding: 8px 32px;
  .modal-title {
    width: 100%;
    .mb-3 {
      margin-bottom: 0px !important;
      padding-top: 6px;
      float: right;
      .col-md-10 {
        padding: 0px;
        max-width: 130px;
      }
    }
    .col-form-label,
    .form-control-plaintext {
      font-family: normalFont;
      font-size: 13px !important;
      padding: 0px;
      line-height: 15px;
      height: 15px;
      min-width: 80px;
    }
  }
}

.modal-body {
  padding: 16px 32px;
}
.modal-dialog {
  max-width: 680px;
}
.modal-content {
  border-radius: 0;
  border-top: 3px solid #0f0f0f;
  cursor: grab;
}

.radiobtns {
  margin-bottom: 1rem;
  display: flex;
  .form-label {
    padding-right: 8px;
    float: left;
  }
  .form-check-label {
    color: inherit !important;
  }
}

.react-draggable {
  margin: 0px !important;
  position: absolute;
  left: 35%;
  top: 16%;
}
