.colorBox {
    background-color: white;
    width: 32px !important;
    height: 32px !important;
    border: 1px solid #ccc;
}

.innerBox {
    width: 26px;
    height: 26px;
    margin: 3px 0px 0px 2px;
}

.popupColorContainer {
    display: flex;
    width: 146px;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background: var(--Background-stateless-base, #FFF);

    /* Elevation/Light/5 */
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12), 0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.08);
}

.colorListSelection {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    &:hover {
        cursor: pointer;
    }

}

.colorBoxInner {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}