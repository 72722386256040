/* Global styles */

html,
body,
#react_main,
#root,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}

button {
  border: 0px;
}
.primarColorBtn {
  box-sizing: border-box;
  border: 1px solid #0c74da;
  background-color: #0c74da;
  border-radius: 0px;
  //line-height: 0.5;
  color: #ffffff;
  font-family: normalFont;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;

  &:hover {
    color: #fff;
    background-color: #2e92fa;
    border-color: #2e92fa;
  }
  &:active {
    color: #fff;
    background-color: #2e92fa;
    border-color: #2e92fa;
  }
}

@font-face {
  font-family: "lightFont";
  src: url("../fonts/ABBvoice_W_Lt.eot"); /* IE9 Compat Modes */
  src: url("../fonts/ABBvoice_W_Lt.woff") format("woff"),
    url("../fonts/ABBvoice_W_Lt.woff2") format("woff2");
}

@font-face {
  font-family: "normalFont";
  src: url("../fonts/ABBvoice_W_Rg.eot"); /* IE9 Compat Modes */
  src: url("../fonts/ABBvoice_W_Rg.woff") format("woff"),
    url("../fonts/ABBvoice_W_Rg.woff2") format("woff2");
}

@font-face {
  font-family: "boldFont";
  src: url("../fonts/ABBvoice_W_Bd.eot"); /* IE9 Compat Modes */
  src: url("../fonts/ABBvoice_W_Bd.woff") format("woff"),
    url("../fonts/ABBvoice_W_Bd.woff2") format("woff2");
}

@font-face {
  font-family: "mediumFont";
  src: url("../fonts/ABBvoice_W_Md.eot"); /* IE9 Compat Modes */
  src: url("../fonts/ABBvoice_W_Md.woff") format("woff"),
    url("../fonts/ABBvoice_W_Md.woff2") format("woff2");
}

body {
  font-family: lightFont, Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
  color: #464646;
}

// .container-fluid {
//    // margin-left: 10px !important;
//   //  margin-right: 10px !important;
// }

body.fontLoaded {
  font-family: lightFont, Helvetica, Arial, sans-serif;
  font-size: 12px; // default
}

.col-clear-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

// fix for scroll issue
.row {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 8px;
}

.remove-bottom-margin {
  margin-bottom: 0px;
}

.pageTitle {
  color: #464646 !important;
  font-size: 18px;
}

.noLeftpadding {
  padding-left: 0px;
}
.noRightpadding {
  padding-right: 0px;
  position: relative;
}

a {
  color: #000;
}

a:hover {
  color: #000;
  text-decoration: none;
}
.boldText {
  font-family: "boldFont";
}
.mediumText {
  font-family: "mediumFont";
}
.addMarginTop {
  margin-top: 16px;
}

.adMarginRight {
  margin-right: 10px;
}

.grayBackGround {
  background-color: #f0f0f0;
}

.whiteBorder {
  border: 1px solid #ffff;
}

.breadcrumbPath {
  position: relative;
  top: 3px;
  left: 5px;
}

.smallButton {
  box-sizing: border-box;
  height: 24px;
  width: 90px;
  border: 1px solid #0c74da;
  background-color: #0c74da;
  border-radius: 0px;
  //line-height: 0.5;
  color: #ffffff;
  font-family: normalFont;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  padding: 0px 0px;

  &:hover {
    color: #fff;
    background-color: #2e92fa;
    border-color: #2e92fa;
  }
  &:active {
    color: #fff;
    background-color: #2e92fa;
    border-color: #2e92fa;
  }
}
.btn:focus {
  box-shadow: none;
}
.datePickerContainer {
  .rw-widget-picker {
    height: auto;
  }
  .datePickerTextbox {
    max-width: 250px;
  }
  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none;
  }
  .duration {
    margin-right: 8px;
    vertical-align: middle;
  }
}

.highcharts-button-symbol {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

button-basic {
  background-color: #0c74da;
  &:hover {
    background-color: #2e92fa;
  }
  &:active {
    background-color: #004c97;
  }
}
.smallbtn {
  box-sizing: border-box;
  height: 24px;
  max-width: 90px;
  width: 100%;
  border: 1px solid #cccccc;
  background-color: #e8e8e8;
  border-radius: 0px;
  color: #000;
  font-family: normalFont;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  padding: 0px 0px;
}

.iconbtn {
  background: none;
  width: 100%;
  padding: 0;
  img {
    float: right;
  }
}

.nodatafound {
  text-align: center;
  margin: 15% auto;
}
.nodatafound div:first-child {
  background-image: url("../assets/images/abb_nodata_information.svg");
  background-repeat: no-repeat;
  padding-left: 10px;
  background-position: left;
  width: 87px;
  line-height: 26px;
  margin: 0 auto;
  margin-bottom: -16px;
}
.btn-link-custom {
  background: transparent;
  border: 1px solid transparent;
  color: #464646;
  text-decoration: none;
  border-radius: 0px;
  font-size: 13px;
  font-family: mediumFont;
}
.btn-link-custom:hover {
  background: #fcfcfc;
  background: rgba(250, 250, 250, 0.6);
  border: 1px solid;
  border-color: #bababa;
  border-color: rgba(140, 140, 140, 0.6);
  color: #464646;
  outline: none;
  text-decoration: none;
}

.conditionIcon {
  position: relative;
  box-sizing: content-box;
  width: 22px;
  height: 22px;
  // border: 1px solid #fff;
  background-size: contain;
}
#goodcondition {
  background-color: #007a33;
  background-image: url("../assets/images/goodFilter.png");
}
#poorcondition {
  background-color: #f03040;
  background-image: url("../assets/images/poorFilter.png");
}
#unknowncondition {
  background-color: #c8c8c8;
  background-image: url("../assets/images/unknownFilter.png");
}

#normalcondition {
  background-color: #fdd702;
  background-image: url("../assets/images/poorFilter.png");
}
.conditioncount {
  min-height: 22px;
  line-height: 22px;
  padding-left: 5px;
  font-size: 12px;
  font-family: normalFont;
}
.conditionbuttonselected {
  background-color: #daedff !important;
  border: 1px solid #0c74da !important;
}

.graystar {
  font-size: 24px;
  color: #d2d2d2;
  line-height: 23px;
  cursor: pointer;
}
.graystar:hover {
  color: #ffea71;
}
.activegraystar {
  font-size: 24px;
  line-height: 23px;
  cursor: pointer;
  color: #ffd800;
  &:hover {
    color: #ffea71;
  }
}

.filterResetButton {
  display: flex;
}

.toogle-link {
  text-align: right;
  a {
    cursor: pointer;
    color: #0c74da !important;
    font-family: normalFont;
    font-weight: normal;
    font-size: 14px;
    text-decoration: underline !important;
  }
}
.overallText {
  color: #464646;
  font-family: mediumFont;
  font-size: 16px;
  font-weight: 500;
}
.fleetinfoborder {
  padding: 8px;
  //border: 1px solid #a0a0a0;
  background-color: #ffffff;
}

.inline {
  float: left;
}

.normal-font {
  font-family: normalFont;
}
.medium-font {
  font-family: mediumFont;
}
.bold-font {
  font-family: "boldFont";
}
.green-square {
  background-color: #007a33;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.red-square {
  background-color: #f03040;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.yellow-square {
  background-color: #ffd800;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0px;
}

.custom-control-input:disabled ~ .custom-control-label {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.5;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-checkbox .custom-control-label:hover::before,
.custom-checkbox
  .custom-control-input:checked:hover
  ~ .custom-control-label:hover::before {
  border: 1px solid #7b7b7b;
  transform: scale(1.1);
}
.custom-radio .custom-control-label:hover::before,
.custom-radio
  .custom-control-input:checked:hover
  ~ .custom-control-label:hover::before {
  border: 1px solid #7b7b7b;
  transform: scale(1.1);
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffffff;
  border-color: #bdbdbd;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%232e92fa' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  transform: scale(1.3);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232e92fa'/%3e%3c/svg%3e");
  transform: scale(1.3);
}

.rw-widget > .rw-widget-container {
  box-shadow: none;
}

.toggle-button {
  max-width: 65px;
  height: 30px;
  font-family: mediumFont;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  background-color: #e8e8e8;
  border-radius: 0px;
  color: #000;
  line-height: 17px;
  text-align: center;
  padding: 0px 0px;
  cursor: pointer;
  &:hover {
    background-color: #fcfcfc;
    color: #464646;
  }
}
.activeLightBlue {
  background-color: #ddedfd;
  border: 1px solid #2e92fa;
  pointer-events: none;
}

.center-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 35vh;
  position: relative;
}

.container-fluid {
  padding-right: 16px;
  padding-left: 16px;
}
.nav-logo {
  padding-left: 8px;
}

.rw-i-caret-down {
  height: 30px;
  line-height: 34px;
}
.rw-i-caret-down:before {
  content: url("../assets/images/caretdown_16.svg") !important;
}
.mockmsg {
  color: red;
  margin-left: 8px;
}
@media only screen and (max-width: 1280px) {
  .overview-grey-info {
    max-width: 31% !important;
    flex: 0 0 31% !important;
  }
  .sideMenucon .FleetListicon {
    //display: none;
  }
}

@media only screen and (max-width: 1120px) {
  #fleet-tabpane-Condition-utilization-vehicleprod-view
    .highcharts-container
    .highcharts-title {
    width: 89% !important;
  }
  .date-filter-selection {
    .showingtxt,
    .date-range-btn-con,
    .datecontainer {
      flex: 100%;
      max-width: 100% !important;
      margin-bottom: 16px;
    }
    .horizental-seperator {
      display: none;
    }
  }
  .datediff {
    margin-top: 0px !important;
  }
  .overview-grey-info {
    margin-right: 8px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1023px) {
  .sideMenucon,
  .detailViewRightinner {
    flex: 100%;
    max-width: 100%;
  }
}

.smallSilverButton {
  box-sizing: border-box;
  height: 24px;
  width: 90px;
  background: rgba(220, 220, 220, 0.6);
  border: 1px solid #c8c8c8;
  border-radius: 0px;
  //line-height: 0.5;
  color: #464646;
  font-family: normalFont;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  padding: 0px 0px;

  &:hover {
    color: #464646;
    background-color: #bababa;
    border-color: #bababa;
  }
  &:active {
    color: #464646;
    background-color: #bababa;
    border-color: #bababa;
  }
}

// temp css to hide non working items

.hidewithspace {
  visibility: hidden !important;
}
.hidewithoutspace {
  display: none !important;
}
.filterContainer #dropdownfleets_input .rw-widget-container {
  border-right: 1px solid #cccccc !important;
}
#center {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  text-align: center;
  img {
    max-width: 50%;
  }
  a {
    color: #00cc66;
  }
}
// ends here

.basebackground {
  background-color: #f5f5f5;
}
