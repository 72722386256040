.btn_close{
    border-radius: 32px;
    background: var(--Background-secondary-default, rgba(0, 0, 0, 0.08));
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    color: var(--Foreground-primary, #1F1F1F);
    text-align: center;
    /* button/medium/default */
    font-family: ABBvoice;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* 100% */
}

.user_guide_title{
    color: var(--Foreground-primary, #1F1F1F);
        /* heading/h5 */
        font-family: ABBvoice;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 120% */
}
.user_guide_sub_title{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    /* body/small/weight-regular */
    font-family: ABBvoice;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    padding: 8px;
    border-radius: 8px;
    background: var(--Status-discreet-info, rgba(0, 81, 255, 0.08));
}

.guide_item{
    display: flex;
    justify-content: space-between;
    cursor:pointer;
    margin-bottom: 5px;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid var(--Border-default, #E0E0E0);
    color: var(--Foreground-primary, #1F1F1F);
    /* label/medium/weight-medium */
    font-family: "ABBvoice";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}

.modalContent{
    display: flex;
    flex-direction: column;
    gap: 16px;

}