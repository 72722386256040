.util-thres-con {
  margin-top: 16px;
  .util-thresould-title {
    font-family: boldFont;
    font-size: 16px;
    text-align: left;
  }

  .util-thresould-tbody {
    td {
      // background: #f5f5f5;
      font-family: normalFont;
      font-size: 14px;
      padding: 4px 16px 4px 16px;
      border: 1px solid #bababa;
      border-collapse: collapse;
    }
    td:nth-child(1) {
      font-family: mediumFont;
      font-size: 14px;
      background: #ebebeb;
      border-right: 2px solid #bababa;
    }
  }
}
