.dropdowncustom{
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    position: relative;
}
.dropdowncustom-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #495057;
}
.dropdowncustom-toggle{
    background-color: #fff;
    color: #495057;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100%;
}
.dropdowncustom-menu{
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 40px;
    left: 0px;
    max-height: 160px;
    overflow-y: auto;   
}
.dropdowncustom-item{
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
}
.dropdowncustom-item:hover{
    background-color: #f1f1f1;
}
.dropdowncustom-item.selected{
    background-color: #007bff;
    color: white;
}