.containersidemenu {
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  //height: 1094px;
  overflow: auto;
  .containerfleetlist {
    padding: 8px 8px 0px 8px;
  }
  .containersearch {
    padding: 0px 8px 0px 8px;
    font-size: 14px;
    font-family: mediumFont;
    .btn {
      margin-left: 5px;
    }
    .chkbox {
      float: right;
      input[type="checkbox"] {
        -webkit-appearance: none;
        // -moz-appearance: none;
        appearance: none;
        // /* create custom checkbox appearance */
        //display: inline-block;
        width: 14px;
        height: 14px;
        //padding: 6px;
        // /* background-color only for content */
        //background-clip: content-box;
        border: 1px solid #bbbbbb;
        //border-radius: 6px;
        //background-color: #e7e6e7;
        //margin-left: 10px;
        //margin-right: 15px;
        vertical-align: middle;

        &:checked {
          background-image: url("../../assets/images/sidemenu/checkboxcheck.svg");
          background-position: center;
          background-repeat: no-repeat;
          //background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%232e92fa' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }

        // &:focus {
        //   outline: none !important;
        // }
      }
    }
    .listfilter{
      background-color: #F5F5F5;
      padding-top: 5px;
      padding-bottom: 8px;
      .titlerow{
        margin-top: 5px;
        margin-bottom: 5px;
      .filter{
        font-size: 16px;
        font-family: mediumFont;
      }
      .reset{
        display: flex;
        align-items: center;
        .reseticon{
          margin-right: 8px;
        }
        font-size: 16px;
        font-family: mediumFont;
        float: right;
        margin-right: 10px;
        background-color: transparent;
      }
      }
      .filterrow{
      margin-top: 3px;
      margin-bottom: 3px;
      .filtertext{
        font-size: 14px;
        font-family: normalFont;
      }
      .filtericons{
        float: right;
        .icon{
          padding: 0px 3px 2px 3px;
          margin-right: 7px;
          background-color: transparent;
          border: 1px solid transparent;
        }
      }
      .activefilter {
        background-color: #ddedfd !important;
        border: 1px solid #2e92fa !important;
        //pointer-events: none;
        color: #004c97;
        border-radius: 3px;
      }
      }
    }
    .selectionfilter {
      margin-top: 10px;
      .sidemenufilterdropdown {
        box-sizing: border-box;
        font-size: 14px;
        font-family: mediumFont;
      }
      .sidemenufilterdropdown .rw-list-option {
        padding: 5px;
      }
      .sidemenufilterdropdown .rw-list-option.rw-state-selected,
      .sidemenufilterdropdown .rw-list-option.rw-state-selected:hover {
        background-color: #2e92fa;
        border-color: #2e92fa;
        color: white;
      }
      .filterButtons {
        background: #ffffff;
        color: #464646;
        text-decoration: none;
        border-radius: 0px;
        font-size: 13px;
        font-family: mediumFont;
        min-height: 32px;
        border: 1px solid #d0d0d0;
        display: -webkit-flex;
        display: flex;
        min-width: auto !important;
        width: 100%;
        padding: 5px;
        border-left: 1px solid transparent;
        margin-left: 0px;
      }
      .filterButtons:disabled {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
      }
      .rw-widget-picker {
        min-height: 40px;
      }
      .filterButtonsContainer {
        text-align: center;
        max-width: 36px;
      }
      #side-menu-vehicles-dropdown {
        flex-grow: 8;
        .rw-widget-picker {
          min-height: 32px;
        }
      }
    }
    .innersearchfieldcon {
      border: 1px solid #d0d0d0;
      // margin-right: 4%;
      margin-top: 5px;
      .searchfilter {
        height: 37px;
        border-bottom: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding: 5px;
        background: transparent;
        width: calc(100% - 30px);
      }
      .search-btn {
        border: none;
        background: none;
        cursor: pointer;
        float: right;
        height: 37px;
      }
    }
    .graystar {
      font-size: 24px;
      color: #d2d2d2;
      line-height: 23px;
      cursor: pointer;
      height: 37px;
      border: 1px solid #d0d0d0;
      margin-top: 5px;
    }
    .graystar:hover {
      color: #ffea71;
    }
    .activegraystar {
      font-size: 24px;
      line-height: 23px;
      cursor: pointer;
      color: #ffd800;
      &:hover {
        color: #ffea71;
      }
    }
  }
  .totalvehicles {
    margin-left: 8px;
    font-family: normalFont;
    font-size: 12px;
  }
  .containervehiclelist {
    padding: 8px;
    max-height: 50vh;
    overflow: auto;
    .accordion {
      border: 0;
      background-color: #f0f0f0;
      .accordion__item {
        border: none;
        .accordion__button {
          height: 41px;
          cursor: pointer;
          //margin: 0 0 1px;
          background-color: #ffffff;
          //width: 100%;
          text-align: left;
          border-left: 4px solid transparent;
          font-family: mediumFont;
          font-size: 14px;
          padding: 10px 8px 10px 10px;
          &:hover {
            //border-left: 4px solid #0c74da;
            box-sizing: border-box;
            background-color: #ebebeb;
          }
          &:focus {
            outline: none !important;
          }
          .vehicleicon {
            margin-right: 5px;
          }
          .vehiclestatusicon {
            float: right;
            .statusicon {
              margin-right: 5px;
            }
          }
        }
        .accordion__button:after {
          margin-top: 5px;
          font-size: 14px;
          color: #474747;
          //content: url(../../assets/images/caretdown_16.svg) !important;
          margin-right: 5px;
          float: left;
        }

        .accordion__button[aria-expanded="true"]:after {
          content: url(../../assets/images/caretdown_16.svg) !important;
        }

        .accordion__button:before {
          display: none !important;
        }
        [aria-expanded="true"] {
          box-sizing: border-box;
          background-color: #e6e6e6;
        }
        .active-site > .accordion__button {
          border-left: 4px solid #0c74da;
          box-sizing: border-box;
          background-color: #ddedfd;
        }
        .accordion__panel {
          height: 41px;
          cursor: pointer;
          margin: 0 0 0px 25px;
          background-color: #f0f0f0;
          text-align: left;
          border-left: 4px solid transparent;
          font-family: mediumFont;
          font-size: 14px;
          padding: 10px 8px 10px 5px;
          &:hover {
            border-left: 4px solid #0c74da;
            box-sizing: border-box;
            background-color: #ddedfd;
          }
          &:focus {
            outline: none !important;
          }
          .productlisticon {
            margin-right: 5px;
            float: left;
          }
          .productstatusicon {
            float: right;
            .statusicon {
              margin-right: 5px;
            }
          }
        }
        .active-product {
          border-left: 4px solid #0c74da;
          box-sizing: border-box;
          background-color: #ddedfd;
        }
      }
    }
    .loading {
      font-family: normalFont;
      font-size: 12px;
    }
  }
  .containerproductlist {
    padding: 8px;
    max-height: 50vh;
    overflow: auto;
    .accordion {
      border: 0;
      // background-color: #f0f0f0;
      .accordion__item {
        border: none;
        .accordion__button {
          height: 41px;
          cursor: pointer;
          //margin: 0 0 1px;
          background-color: #ffffff;
          //width: 100%;
          text-align: left;
          border-left: 4px solid transparent;
          font-family: mediumFont;
          font-size: 14px;
          padding: 10px 8px 10px 10px;
          &:hover {
            // border-left: 4px solid #0c74da;
            box-sizing: border-box;
            background-color: #ebebeb;
          }
          &:focus {
            outline: none !important;
          }
          .productlisticon {
            margin-right: 5px;
            float: left;
          }
          .productstatusicon {
            float: right;
            .statusicon {
              margin-right: 5px;
            }
          }
        }
        .accordion__button:after {
          margin-top: 5px;
          font-size: 14px;
          color: #474747;
          //content: url(../../assets/images/caretdown_16.svg) !important;
          margin-right: 5px;
          float: left;
        }

        .accordion__button[aria-expanded="true"]:after {
          //content: url(../../assets/images/caretup_16.svg) !important;
        }

        .accordion__button:before {
          display: none !important;
        }
        [aria-expanded="true"] {
          box-sizing: border-box;
          // background-color: #e6e6e6;
        }
        .active-site > .accordion__button {
          border-left: 4px solid #0c74da;
          box-sizing: border-box;
          background-color: #ddedfd;
        }
        .active-product {
          border-left: 4px solid #0c74da;
          box-sizing: border-box;
          background-color: #ddedfd;
        }
      }
    }
    .loading {
      font-family: normalFont;
      font-size: 12px;
    }
  }
  .containervehicleproductlist {
    padding: 8px;
    max-height: 50vh;
    overflow: auto;
    .accordion {
      border: 0;
      //background-color: #f5f5f5;
      .accordion__item {
        border: none;
        .accordion__button {
          height: 41px;
          cursor: pointer;
          //margin: 0 0 1px;
          background-color: #ffffff;
          //width: 100%;
          text-align: left;
          border-left: 4px solid transparent;
          font-family: mediumFont;
          font-size: 14px;
          padding: 0px;
          line-height: 41px;
          &:hover {
            //border-left: 4px solid #0c74da;
            box-sizing: border-box;
            background-color: #ebebeb;
            &::after {
              content: url(../../assets/images/sidemenu/caretdownhover.svg) !important;
            }
          }
          &:focus {
            outline: none !important;
          }
          .headingcontainer {
            width: 88%;
            display: block;
            float: right;
            .vehicleicon {
              margin-right: 5px;
              line-height: 37px;
              float: left;
            }
            .vehicletext {
              //padding: 10px 5px 10px 0px;

              //line-height: 37px;
              line-height: 40px;
              overflow: hidden;
              white-space: nowrap;
              max-width: 70%;
              float: left;
              @media only screen and (max-width: 1200px) {
                max-width: 45%;
              }
            }
            .vehiclestatusicon {
              float: right;
              line-height: 37px;
              padding-right: 5px;
              .statusicon {
                margin-right: 5px;
              }
            }
          }
        }
        .accordion__button:after {
          margin-top: 5px;
          font-size: 14px;
          color: #474747;
          content: url(../../assets/images/caret-right_16.svg) !important;
          margin-right: 3px;
          //float: left;
          margin-left: 5px;
          line-height: 48px;
        }

        .accordion__button[aria-expanded="true"]:after {
          content: url(../../assets/images/caretdown_16.svg) !important;
        }

        .accordion__button:before {
          display: none !important;
        }
        [aria-expanded="true"] {
          box-sizing: border-box;
          background-color: #ebebeb;
          &:hover {
            &::after {
              content: url(../../assets/images/caretdown_16.svg) !important;
            }
          }
        }
        .active-site > .accordion__button {
          border-left: 4px solid #0c74da;
          box-sizing: border-box;
          background-color: #ddedfd;
          &:hover {
            &::after {
              content: url(../../assets/images/sidemenu/caretdownhover_a.svg) !important;
            }
          }
        }
        .active-site > .accordion__button[aria-expanded="true"] {
          border-left: 4px solid #0c74da;
          box-sizing: border-box;
          background-color: #ddedfd;
          &:hover {
            &::after {
              content: url(../../assets/images/caretdown_16.svg) !important;
            }
          }
        }
        .accordion__panel {
          height: 41px;
          cursor: pointer;
          margin: 2px 0 0px 8%;
          //background-color: #f5f5f5;
          text-align: left;
          border-left: 4px solid transparent;
          font-family: mediumFont;
          font-size: 14px;
          padding: 10px 8px 10px 5px;
          &:hover {
            //border-left: 4px solid #0c74da;
            box-sizing: border-box;
            background-color: #ebebeb;
          }
          &:focus {
            outline: none !important;
          }
          .productlisticon {
            margin-right: 5px;
            float: left;
          }
          .producttext {
            line-height: 22px;
            overflow: hidden;
            white-space: nowrap;
            max-width: 57%;
            float: left;
            @media only screen and (max-width: 1200px) {
              max-width: 45%;
            }
          }
          .productstatusicon {
            float: right;
            .statusicon {
              margin-right: 5px;
            }
          }
        }
        .active-product {
          border-left: 4px solid #0c74da;
          box-sizing: border-box;
          background-color: #ddedfd;
          &:hover {
            //border-left: 4px solid #0c74da;
            box-sizing: border-box;
            background-color: #ddedfd;
          }
        }
      }
    }
    .loading {
      font-family: normalFont;
      font-size: 12px;
    }
  }

  // /**  STYLE 11 */
  .containervehicleproductlist::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }
  .containervehicleproductlist::-webkit-scrollbar-track {
    //  border-radius: 10px;
    background: white;
    border: 0;
  }

  .containervehicleproductlist::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    background: linear-gradient(left, #fff, #e4e4e4);
    border: 0.1px solid #aaa;
    border-radius: 5px;
  }

  .containervehicleproductlist::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  .containervehicleproductlist::-webkit-scrollbar-thumb:active {
    background: linear-gradient(left, #a8a8a8, #a8a8a8);
  }
}

.unmountedesscon {
  border-top: 8px solid #f2f2f2;
  padding-top: 8px;
  background: #ffffff;
  .unmounted-ess {
    height: auto !important;
    max-height: 100% !important;
    overflow: auto;
    .accordion {
      .accordion__item {
        .accordion__button {
          &:hover {
            background: none !important;
            &::after {
              // content: url(../../assets/images/sidemenu/caret-right_16.svg) !important;
            }
          }
        }
        [aria-expanded="true"] {
          background: none !important;
        }
      }
    }
    .unmountedlist {
      max-height: 30vh;
      overflow: auto;
      width: 100%;
    }
    .unmounted_nodata {
      cursor: default !important;
      font-family: normalFont !important;
      font-size: 13px !important;
      &:hover {
        background: none !important;
        cursor: default !important;
      }
    }
  }
}

.frmsection {
  background-color: #fff;
  //border: 1px solid #a0a0a0;
  margin: 0px;
  border-bottom: 0;
  clear: both;
  .frmcontrols {
    padding-bottom: 0;
    label {
      margin-bottom: 2px;
    }
    label:after {
      // content: "*";
      //color: red;
    }
    .form-control {
      border-radius: 0;
      max-height: 32px;
      font-family: "normalFont";
      font-size: 14px;
    }
    .form-control:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
  .filterbtn{
    background-color: #DCDCDC99;
    border: 1px solid #C8C8C899;
    border-radius: 0px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;

    width: 32px;
    height: 32px;
    float: right;
  }
}

// .expandlefticon{
//   position: absolute;
//   top: 50%;
//   right: -5%;
//   z-index: 1;
//   cursor: pointer;
//   @media only screen and (max-width: 1022px) {
//     display: none;
//   }
//   // text-align: center;
//   // .expandleft{
//   //   //margin-left: 165px;
//   //   margin-bottom: 15px;
//   // }
// }
.expandlefticon {
  position: fixed;
  top: 50%;
  background: #ddedfd;
  left: 0px;
  z-index: 9999;
  cursor: pointer;
  padding: 5px;
  box-shadow: 3px 0 3px #ada5a5;
  border-radius: 0 5px 5px 0;
  @media only screen and (max-width: 1022px) {
    display: none;
  }
}

.dxc{
  .accordion__panel{
    margin: 2px 0 0px 16% !important;

  }
}

