

.form-row > input:nth-child(1),
.form-row > input:nth-child(5) {
  max-width: 80px;
}

.form-row > select:nth-child(2),
.form-row > select:nth-child(4) {
  max-width: 60px;
  text-align: center;
}
.form-header div:nth-child(2),.form-header div:nth-child(2){
  max-width: 60px;
} 
.form-header div:nth-child(1),.form-header div:nth-child(4){
  max-width: 75px;
} 


.section {
  margin: 20px 0;
  padding: 20px;
  // background-color: #fff;
  color: var(--Foreground-secondary, #696969);
  font-size: 14px;

}

.section h2 {
  margin-bottom: 20px;
}
.form-header{
  border-bottom: 1px solid var(--Border-primary-focus-black, #000);
  padding-bottom: 10px;
  font-weight: 500;
}
.form-header,
.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

}
.form-row{
  border-bottom: 1px solid var(--Border-stateless-subtle, rgba(0, 0, 0, 0.12));
padding-bottom: 8px;
}

.form-header div,
.form-row input,
.form-row span,
.form-row select {
  flex: 1;
}

.form-header div:nth-child(1),
.form-row input:nth-child(1) {
  text-align: right;
  margin-right: 10px;
}

.form-header div:nth-child(2),
.form-row select:nth-child(2) {
  text-align: center;
  margin: 0 10px;
}

.form-header div:nth-child(3),
.form-row span:nth-child(3) {
  text-align: center;
  margin: 0 10px;
}

.form-header div:nth-child(4),
.form-row select:nth-child(4) {
  margin: 0 10px;
}
.form-header div:nth-child(4){
  max-width: 60px;
  text-align: left;
}

.form-header div:nth-child(5),
.form-row input:nth-child(5),
.form-header div:nth-child(6),
.form-row input:nth-child(6) {
  text-align: left;
  margin-left: 10px;
}
.form-header div:nth-child(5){
  max-width: 110px;
}

.form-row input,
.form-row select {
  padding: 8px;
  border-radius: 0px;
  font-family: ABBvoice;
font-size: 14px;
font-style: normal;
font-weight: 400;
}
.topSection{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.topSection div {
  color: var(--Global-black-primary, #000);

  font-family: ABBvoice;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}