.chart {
  //cursor: zoom-in;

  //margin-left: 18px;

  max-width: 100%;

  .highcharts-root {
    max-width: 100%;
    font-family: lightFont, Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    height: 100%;
  }

  .highcharts-legend-item {
    .highcharts-point {
      // fill: none !important;
      //stroke-width: 0 !important;
    }

    .highcharts-graph {
      stroke-width: 6 !important;
    }
  }

  .highcharts-button-box {
    fill: transparent;
  }
}

.highcharts-background {
  cursor: zoom-in;
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.chart-style {
  // margin: auto;
  // display: block;
  vertical-align: middle;
}

// .highcharts-markers {
//   opacity: 0.6;
// }
.highcharts-series-hover {
  // opacity: 0.4;
}

// .highcharts-series-0:hover ~ .highcharts-series-1 {
//   opacity: 0.2;
// }
// .highcharts-series-1:hover ~ .highcharts-series-0 {
//   opacity: 0.2;
// }

.highcharts-axis-labels text,
.highcharts-tooltip text tspan {
  font-size: 12px !important;
  font-family: normalFont !important;
}

.highcharts-container .highcharts-title {
  font-size: 16px !important;
  font-family: "mediumFont" !important;
  width: 95% !important;
  border-bottom: 2px solid #1f1f1f;
  padding-bottom: 5px;
  display: "block";
  top: 3px !important;
}

// .highcharts-yaxis-grid .highcharts-grid-line:first-child {
//   stroke: #000;
//   stroke-width: 2;
// }

#fleet-tabpane-Condition-utilization-vehicleprod-view .highcharts-container .highcharts-title {
  width: 93% !important;
}

.highcharts-contextmenu {
  top: 20px !important;

  .highcharts-menu {
    box-shadow: none !important;
    border: 1px solid rgb(153, 153, 153) !important;
    background: rgb(255, 255, 255) !important;
    padding: 5px 0px !important;

    .highcharts-menu-item {
      font-size: 12px !important;
      font-family: normalFont !important;
    }
  }
}

.sampleinterval {
  font-size: 14px;
  font-family: "lightFont";
}

#fleet-tabpane-Overview .highcharts-background {
  cursor: default;
}

.per-val {
  font-size: 16px;
}

#pie_graph .highcharts-legend-item .highcharts-point,
#pie_graph .highcharts-legend-item .highcharts-graph {
  display: none;
}

#pie_graph .highcharts-legend-item>span {
  font-size: 12px !important;
  font-family: "lightFont" !important;
  font-weight: 500 !important;

  img {
    max-height: 16px;
    max-width: 16px;
  }
}

.haveinnertabs {
  .highcharts-point {
    stroke-width: 0px;
  }
}

.haveinnertabs #stock_graph {
  .highcharts-point-select {
    stroke-width: 0;
  }

  .highcharts-point {
    stroke-width: .7px;
  }

  .highcharts-point-select {
    width: 34px;
    transform: translateX(-5px);
  }

  .highcharts-background {
    cursor: default;
    fill: transparent;
  }

  .highcharts-tooltip>span {
    background: #fafafa;
    padding: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-width: 160px;
    max-height: 260px;
    overflow-y: auto;
  }

  .highcharts-tooltip>span>div {
    padding: 4px;
  }

  .toolheader {
    font-family: "mediumFont" !important;
    font-size: 14px;
    border-bottom: 2px solid #cccccc;
    cursor: pointer !important;
  }

  .toolcontent {
    font-size: 14px;
    font-family: lightFont, Arial, Helvetica, sans-serif !important;
    cursor: pointer !important;

  }

  .toolcontent:hover {
    background-color: #ebebeb;
  }

  // #pname:hover {
  //   background-color: #ebebeb;
  // }
  .toolcontent:after {
    margin-top: 0px;
    font-size: 14px;
    color: #474747;
    content: url(../../assets/images/caretright.svg) !important;
    margin-right: 0;
    float: right;
  }

  .hiderightarrow {
    cursor: default;
    color: #474747 !important;
    font-family: lightFont, Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
  }

  .hiderightarrow:hover {
    background: none;
  }

  .hiderightarrow:after {
    margin-top: 0px;
    font-size: 14px;
    color: #474747;
    content: "" !important;
    margin-right: 0;
    float: right;
  }

  #sname {
    cursor: pointer;
  }

  #pname {
    cursor: default;
  }
}

#stock_graph,
#stock_graph .highcharts-container {
  overflow: visible !important;
}

.stackcontent {
  transform: rotate(-90deg);
}

.highcharts-stack-labels {
  opacity: 1 !important;
}

#vehicleviewtabs {
  .highcharts-point-select {
    width: 11px !important;
    transform: translateX(0px) !important;
  }

  .highcharts-axis-labels {
    span {
      font-size: 12px !important;
      font-family: normalFont !important;
      font-weight: 500 !important;
      color: #686868 !important;
      // .stackcontent {
      //   font-size: 12px !important;
      //   font-family: normalFont !important;
      // }
    }
  }

  .highcharts-stack-labels {
    span {
      font-size: 10px !important;
      font-family: normalFont !important;
      font-weight: 500 !important;
      color: #686868 !important;
      // .stackcontent {
      //   font-size: 12px !important;
      //   font-family: normalFont !important;
      // }
    }
  }

  #stock_graph,
  #stock_graph .highcharts-container {
    overflow: visible !important;
  }

  .highcharts-background {
    cursor: default;
    fill: transparent;
  }
}

.highcharts-axis-labels text,
.highcharts-tooltip text tspan {
  font-size: 12px !important;
  font-family: normalFont !important;
}

#pie_graph .highcharts-background {
  cursor: default;
  fill: transparent;
}