.filterContainer {
  .conditioncount {
    font-size: 14px;
    font-family: mediumFont;
    padding-left: 8px;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
  }
  .dashboardsearchfilter {
    font-size: 14px;
    font-family: mediumFont;
  }
  .rw-dropdown-list-input,
  .rw-list-option {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1600px) {
  .filterContainer {
    .conditioncount {
      font-size: 16px;
    }
    .dashboardsearchfilter {
      font-size: 16px;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) {
  .full-width {
    max-width: 100%;
    flex: 100%;
    margin-bottom: 20px;
  }
  .filterContainer {
    #dropdownsites_input,
    #dropdowncustomers_input,
    #dropdownfleets_input {
      background: none;
      padding-left: 0px;
    }
    .filterButtons {
      min-width: auto;
      justify-content: center; /* center items vertically, in this case */
      align-items: center;
    }
    .conditioncount {
      display: none;
    }
  }
}
.filterContainer .rw-popup-container{
  left: 1em !important;
}