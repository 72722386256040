.sidemenulist-scroll {
  max-height: 23vh;
  overflow: auto;
  .conditionIcon {
    box-sizing: border-box;
    margin-right: 8px;
  }
  .conwithstar {
    max-width: 58px;
    .graystar {
      font-size: 29px;
      line-height: 24px;
    }
  }
}
.sidemenulist-scroll::-webkit-scrollbar {
  width: 10px;
  //background-color: #f5f5f5;
}
.sidemenulist-scroll::-webkit-scrollbar-track{
  //  border-radius: 10px;
  //background: rgba(0, 0, 0, 0.1);
  border: 0px solid #ccc;
}
.sidemenulist-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}
.sidemenulist-scroll::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.sidemenulist-scroll::-webkit-scrollbar-thumb:active{
  background: linear-gradient(left, #a8a8a8, #a8a8a8);
}
.FleetListtitle {
  color: #464646;
  font-size: 16px;
  font-family: mediumFont;
}
.FleetListsubtitle {
  color: #464646;
  font-size: 14px;
  font-family: mediumFont;
  .arrowhover {
    padding-right: 4px;
    width: 24px;
    height: 24px;
    float: right;
    &:hover {
      background-color: rgba(132, 132, 132, 0.3);
      border-radius: 50px;
    }
  }
}
.FleetListcontainer {
  padding: 9px;
  cursor: pointer;
  margin: 0 0 1px;
  background-color: #f0f0f0;
  width: 100%;
  text-align: left;
  border-left: 4px solid transparent;
  font-family: mediumFont;
  font-size: 14px;
  &:hover {
    border-left: 4px solid #0c74da;
    box-sizing: border-box;
    background-color: #ddedfd;
  }
}

.fleetlistactive {
  border-left: 4px solid #0c74da;
  box-sizing: border-box;
  background-color: #ddedfd;
  //pointer-events: none;
}

.FleetListactive {
  border-left: 4px solid #0c74da;
  box-sizing: border-box;
  background-color: #ddedfd;
}

.basicfiltertitle {
  color: #464646;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.basicfilteraccordion {
  padding: 0px 0px 0px 0px;
  .accordion {
    //width: 100%;
    border: 0;
    //background-color: #f0f0f0;
    .accordion-item {
      //width: 100%;
      .title {
        //width: 100%;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 5px;
        min-height: 50px;
      }
      .panel {
        //width: 100%;
        background-color: #fafafa;
        padding: 0px 0px 0px 0px;
        font-size: 16px;
        font-weight: 600;
        padding-left: 0px;
      }
    }
  }
}

.advancefiltertitle {
  color: #464646;
  font-size: 16px;
  font-weight: bold;
}
.advancefilteraccordion {
  padding: 0px 0px 0px 0px;
  .accordion {
    //width: 100%;
    border: 0;
    .accordion-item {
      //width: 100%;
      .title {
        //width: 100%;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 5px;
        min-height: 50px;
      }
      .panel {
        //width: 100%;
        background-color: #fafafa;
        padding: 0px 0px 0px 0px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.elements {
  padding: 5px 0px 5px 38px;
}

.accordiontitleicon {
  padding-right: 8px;
}

.accordiontitlevalue {
  color: #2e92fa;
}

.filterroutelist {
  max-height: 140px;
  overflow-y: scroll;
}
.clrbtn {
  padding-right: 0px;
  text-align: right;
}

.sideMenucon .FleetListicon {
  margin-right: 6px;
}

#popover-basic {
  margin-right: -215px !important;
  font-size: 12px;
  padding: 10px;
  border-radius: 0;
  .conditionIcon {
    width: 16px;
    height: 16px;
    box-sizing: inherit;
    vertical-align: middle;
  }
  .inline-block {
    display: inline-block;
    margin-right: 5px;
  }
}

#ess-icon {
  background-image: url("../../assets/images/ESS 24.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 22px;
  background-size: 16px 16px;
}

#wwo-icon {
  background-image: url("../../assets/images/abb_wheel_16.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 22px;
  background-size: 16px 16px;
}
#product-icon {
  background-image: url("../../assets/images/abb_object_16.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 22px;
  background-size: 16px 16px;
}

.overlay-small {
  font-size: 12px;
}
.overlay-medium {
  font-size: 14px;
}

.nodatanotificationcontainer-fv {
  max-width: 365px;
  width: 100%;
  //position: absolute;
  right: 15px;
  z-index: 10;
  -webkit-box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.33);
  border: 1px solid #b4b4b4;
  background-color: #ffffff;
  margin-top: 10px;
}

#nodatanotification-fv {
  border-radius: 0px;
  padding: 5px;
  margin-bottom: 0px;

  .close {
    padding: 0px;
    top: 7px;
    right: 15px;
    color: #464646;
  }
}
.nodatanotificationcontent-fv {
  background-image: url("../../assets/images/Icon_info.svg");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
  background-size: 24px 24px;
  margin-bottom: 0px;
  line-height: initial;
  margin-left: 5px;
  font-family: mediumFont;
  font-size: 14px;
}

.rw-list {
  font-size: 14px;
}
.marginbottomZero{
  margin-bottom:1px !important;
}