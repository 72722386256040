.innerFilterContainer {
  background-color: #fafafa;
  padding: 10px;
}

.date-tabs-container {
  border-top: 15px solid #f4f4f4;
 // border: 0px solid #a0a0a0;
 // margin-top: 20px;
  min-height: 45vh;
  background-color: #ffffff;
}

.detailViewRightinner {
  overflow: hidden;
  //position: absolute;
  width: 100%;
  background-color: #ffffff;
}

.vehicle_view_event{
min-height: 100vh !important;
}
