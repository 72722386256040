.sk-circle {
  margin: 10% auto;
  width: 100px;
  height: 100px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #0091ff;
  border-right: 8px solid #0091ff;
  border-bottom: 8px dashed #dbdbdb;
  border-left: 8px solid #0091ff;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.loadingtext {
  position: relative;
  top: 103%;
  font-size: 16px;
}
// .sk-circle .sk-child:before {
//   // content: url("../../assets/images/loader.png");
//   // display: block;
//   // margin: 0 auto;
//   // width: 15%;
//   // height: 15%;
//   // background-color: rgb(120, 120, 120);
//   // // border-radius: 100%;
//   // -webkit-animation: spin 1s linear infinite;
//   // animation: spin 1s linear infinite;
// }
// .sk-circle .sk-circle2 {
//   -webkit-transform: rotate(30deg);
//   -ms-transform: rotate(30deg);
//   transform: rotate(30deg);
// }
// .sk-circle .sk-circle3 {
//   -webkit-transform: rotate(60deg);
//   -ms-transform: rotate(60deg);
//   transform: rotate(60deg);
// }
// .sk-circle .sk-circle4 {
//   -webkit-transform: rotate(90deg);
//   -ms-transform: rotate(90deg);
//   transform: rotate(90deg);
// }
// .sk-circle .sk-circle5 {
//   -webkit-transform: rotate(120deg);
//   -ms-transform: rotate(120deg);
//   transform: rotate(120deg);
// }
// .sk-circle .sk-circle6 {
//   -webkit-transform: rotate(150deg);
//   -ms-transform: rotate(150deg);
//   transform: rotate(150deg);
// }
// .sk-circle .sk-circle7 {
//   -webkit-transform: rotate(180deg);
//   -ms-transform: rotate(180deg);
//   transform: rotate(180deg);
// }
// .sk-circle .sk-circle8 {
//   -webkit-transform: rotate(210deg);
//   -ms-transform: rotate(210deg);
//   transform: rotate(210deg);
// }
// .sk-circle .sk-circle9 {
//   -webkit-transform: rotate(240deg);
//   -ms-transform: rotate(240deg);
//   transform: rotate(240deg);
// }
// .sk-circle .sk-circle10 {
//   -webkit-transform: rotate(270deg);
//   -ms-transform: rotate(270deg);
//   transform: rotate(270deg);
// }
// .sk-circle .sk-circle11 {
//   -webkit-transform: rotate(300deg);
//   -ms-transform: rotate(300deg);
//   transform: rotate(300deg);
// }
// .sk-circle .sk-circle12 {
//   -webkit-transform: rotate(330deg);
//   -ms-transform: rotate(330deg);
//   transform: rotate(330deg);
// }
// .sk-circle .sk-circle2:before {
//   -webkit-animation-delay: -1.1s;
//   animation-delay: -1.1s;
// }
// .sk-circle .sk-circle3:before {
//   -webkit-animation-delay: -1s;
//   animation-delay: -1s;
// }
// .sk-circle .sk-circle4:before {
//   -webkit-animation-delay: -0.9s;
//   animation-delay: -0.9s;
// }
// .sk-circle .sk-circle5:before {
//   -webkit-animation-delay: -0.8s;
//   animation-delay: -0.8s;
// }
// .sk-circle .sk-circle6:before {
//   -webkit-animation-delay: -0.7s;
//   animation-delay: -0.7s;
// }
// .sk-circle .sk-circle7:before {
//   -webkit-animation-delay: -0.6s;
//   animation-delay: -0.6s;
// }
// .sk-circle .sk-circle8:before {
//   -webkit-animation-delay: -0.5s;
//   animation-delay: -0.5s;
// }
// .sk-circle .sk-circle9:before {
//   -webkit-animation-delay: -0.4s;
//   animation-delay: -0.4s;
// }
// .sk-circle .sk-circle10:before {
//   -webkit-animation-delay: -0.3s;
//   animation-delay: -0.3s;
// }
// .sk-circle .sk-circle11:before {
//   -webkit-animation-delay: -0.2s;
//   animation-delay: -0.2s;
// }
// .sk-circle .sk-circle12:before {
//   -webkit-animation-delay: -0.1s;
//   animation-delay: -0.1s;
// }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
