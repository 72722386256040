.errorPages {
  /* stylelint-disable */
}

.page-header {
  font-family: mediumFont;
  font-size: 200%;
  color: #000000;
  padding-left: 10px;
  border-bottom: 0px !important;
  @media only screen and (max-width: 768px) {
    background: #f0f0f0;
    margin-left: -10px !important;
    margin-right: -10px !important;
    margin-top: 0px !important;
    font-size: 20px;
    padding: 16px;
  }
}

.page-content-row {
  width: 98vw;
  // height: 100vh;
}
.page-content-div {
  text-align: center;
  margin: 0 auto;
  width: 514px;
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
  border: 1px solid #eeeeee;

  h1 {
    font-family: mediumFont;
    color: #000000;
    font-size: 24px;
    font-weight: bold;
  }
  h3 {
    color: #000000;
    font-family: mediumFont;
    font-size: 16px;
    font-weight: light;
  }
  button {
    padding: 5px;
    color: #fff;
    background-color: #0c74da;
    border-color: #0c74da;
    font-size: 14px;
    font-family: normalFont;
    font-weight: normal;
    border-radius: 0px;
    margin-bottom: 10px;
    &:hover {
      color: #fff;
      background-color: #2e92fa;
      border-color: #2e92fa;
    }
  }
}
