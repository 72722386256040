.info-title {
  font-size: 16px;
  margin-bottom: 8px;
}
.left-border {
  border-left: 1px solid #a0a0a0;
}
.battery-condition-info,
.battery-overview-info {
  // background: #fafafa;
  // padding: 16px 16px 8px 16px;
}
#fleet-tabpane-Overview-vehicleprod-view .battery-condition-info {
  margin-bottom: 16px;
}

.info-label {
  font-size: 14px;
}
.info-value {
  font-size: 20px;
  margin-bottom: 8px;
  span {
    font-size: 12px;
    position: relative;
    bottom: -5px;
  }
}

.battery-overview-info {
  margin-bottom: 16px;
  .sub {
    font-size: 14px;
    font-family: mediumFont;
    margin-right: 16px;
    margin-bottom: 16px;
    display: inline-block;
  }
}

.adjustheight {
  height: 88%;
}
.battery-condition-info {
  .highcharts-container .highcharts-title {
    border-bottom: 0px solid #1f1f1f;
  }
}
