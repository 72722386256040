#soc-table {
  margin-bottom: 16px;
  .table-header th {
    border-top: 1px solid #1f1f1f;
    border-bottom: 2px solid #1f1f1f;
    font-weight: initial;
  }
  tbody > tr:last-child {
    border-bottom: 1px solid #1f1f1f;
  }
  .left-border {
    border-left: 1px solid #1f1f1f;
    text-align: center;
  }
}

#soc-table td,
#soc-table th {
  padding: 8px;
  vertical-align: text-bottom;
}
