.navbar {
  padding: 0px 10px;
  background-color: #ffffff;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  .navbar-nav {
    min-height: 50px;
    line-height: 50px;
  }
  .navbar-collapse.collapse {
    height: 40px !important;
  }
  .navbar-toggler {
    background-color: #e8e8e8;
  }
  .nav-item {
    a.nav-link {
      padding: 0px 8px;
      // border-right: 1px solid #262626;
      color: #464646 !important;
      font-size: 16px;
      font-family: normalFont;
      &:hover {
        //color: #acacac !important;
        filter: none;
        background: #ebebeb;
      }
      &:active {
        color: #464646 !important;
      }
      &:focus {
        color: #464646 !important;
      }
      select {
        background: transparent;
      }
      select:hover {
        background: #ebebeb;
      }
    }
  }
  &.navbar-default {
    background-color: #222222;
    border: none;
    height: 54px;
    border-radius: 0px;
    width: 100%;

    @media only screen and (max-width: 767px) {
      border-bottom: 1px solid black;
      margin-left: 0px;
      width: 100%;
      height: 50px;
    }
    .container-fluid {
      padding-left: 10px !important;
    }

    margin-bottom: 0;
    flex: 1;

    li {
      height: 100%;
      margin: 0px;
      padding: 5px;
      outline: none;
      font-family: mediumFont;

      font-size: 14px;
      border-right: 1px solid;
      border-left: 1px solid;
      border-color: #000000;

      a {
        color: #bbbbbb !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        &:hover {
          color: #ffffff !important;
        }
        &:active {
          color: #ffffff !important;
        }
        &:focus {
          color: #ffffff !important;
        }
      }
    }

    #icon-commonux-down {
      fill: #bbbbbb;
    }

    .navbar-nav .open {
      background-color: #444444;

      a {
        background-color: #444444 !important;

        &:focus {
          color: #fff;
          border: none;
        }
        &:active {
          color: #fff;
        }
        &:hover {
          color: #fff;
        }
      }

      ul {
        font-size: 14px;
        width: 200px;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        background-color: #444444;
      }
      li {
        height: 100%;
        font-family: normalFont !important;
        font-size: 14px;
        border-bottom: 1px solid #666666;
        a {
          margin-top: 5px;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
        }
      }
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .helpmenu {
    padding: 0 !important;
    width: 50px;
    text-align: center;
  }
  .dropdown-menu {
    right: 0;
    left: auto;
    padding: 8px 0px 16px 0px;
    margin: 0px;
    min-width: 125px;
    border-radius: 0;
    border: 0;
    box-shadow: 0px 6px 12px rgba(15, 15, 15, 0.35);

    .dropdown-item {
      line-height: 32px;
      padding: 0px 8px 0px 16px;
      text-align: left;
      height: 32px;
      font-family: normalFont !important;
      font-size: 16px;
      color: #464646;
      border-radius: 0;
      &:hover {
        background: #ebebeb;
        color: #464646;
      }
      &:active {
        background: #2e92fa;
        color: #fff;
      }
    }
  }
}

.navbar-toggle.navbar-left {
  float: left;
  margin-left: 1px;
  //line-height: 20px !important;
}

.nav-logo {
  // padding-top: 8px;
  div {
    .red-mark {
      margin: 5px 0px 0px 0px !important;
      @media only screen and (max-width: 767px) {
        margin-top: 10px !important;
      }
      width: 24px;
      background-color: #ec1e24;
      height: 4px;
    }
    .abblogo {
    }
    .site-name {
      margin-top: 2px;
      margin-bottom: 0px;
      color: #1f1f1f;
      font-family: mediumFont;
      font-size: 20px;
      border-left: 2px solid #666666;
      padding-left: 8px;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
      button {
        color: #4f89c8;
        font-family: mediumFont;
        font-weight: normal;
        font-size: 14px;
        svg {
          margin-right: 2px;
        }
      }
      .seperator-header {
        height: 20px;
        border-left: 2px solid #666666;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}

.nav-header-text > a {
  display: inline;
  margin-left: 5px;
  vertical-align: middle;
  font-family: mediumFont;
  font-size: 16px;
  color: #222222;
  letter-spacing: -0.49px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}

.nav-email-address-dropdown {
  margin-top: 5px;
  vertical-align: middle;
  font-family: mediumFont;
  font-size: 16px;
  color: #222222;
  letter-spacing: -0.49px;
  .caret {
    display: none;
  }
  li {
    font-size: 16px;
  }
  &:hover {
    #icon-commonux-down {
      fill: #fff;
    }
  }
}

.nav-sites-dropdown {
  ul,
  li {
    width: 300px !important;
    a {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      -o-text-overflow: ellipsis !important;
      padding-right: 10px !important;
    }
  }
}

.nav-header-mobile {
  font-family: mediumFont;
  font-size: 16px;
  color: #fff;
}

.navbar-brand {
  padding: 0px !important;
  cursor: pointer;

  font-size: 16px;
  display: inline;
  // white-space: pre-wrap; /* CSS3 */
  // white-space: -moz-pre-wrap; /* Firefox */
  // white-space: -pre-wrap; /* Opera <7 */
  // white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.navbar-toggle {
  color: #fff;
  padding: 0px !important;
  border: none !important;
  border-radius: 0px !important;
  border-right: 1px solid !important;
  border-color: #000000 !important;
  margin-top: 2px !important;
  margin-right: 2px !important;
  margin-left: 0px !important;
  margin-bottom: 2px !important;
  svg {
    #icon-desktop-options-idle {
      stroke: #fff;
    }
  }
  background-color: #222222 !important;
  &:hover {
    background-color: #222222 !important;
  }
  &:focus {
    background-color: #222222 !important;
  }
  &:active {
    background-color: #222222 !important;
  }
}

.mobile-nav-content {
  font-size: 14px !important;
  ul {
    padding-left: 0px !important;
  }
  li {
    font-family: mediumFont;
    font-weight: normal;
    color: #bbbbbb;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
  margin-top: 0px;
  margin-left: -15px !important;
  display: none;
  position: absolute;
  background-color: #444444;
  // min-width: 250px;
  // max-width: 350px;
  width: 97%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-color: #bcbcbc;

  a {
    color: #bcbcbc;
    font-weight: normal;
  }
  .seperator {
    padding: 0px !important;
    width: 100%;
    border-top: 1px solid #bcbcbc;
  }
  .user-logged {
    font-family: normalFont;
    font-size: 12px;
    color: #bcbcbc;
    margin-left: 5px !important;
  }
  .user-info {
    font-family: normalFont;
    font-size: 12px;
    color: #bcbcbc;
    margin-left: 5px !important;
    margin-right: 2px;
  }
}

.mobile-nav-show {
  display: block;
  svg {
    #icon-desktop-options-idle {
      stroke: #fff;
    }
  }
}

.icon-text-comb {
  color: #bbbbbb;
  outline: none;
  &:hover {
    color: #ffffff !important;
  }
  &:active {
    color: #ffffff !important;
  }
  &:focus {
    color: #ffffff !important;
  }
  svg {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.btn-feedback {
  a,
  u {
    text-decoration: none !important;
  }
}

.languageSelection {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;

  //background-color: #262626;
  border: 0px;
  color: #464646 !important;
  cursor: pointer;
  padding: 0px 13px;

  &:hover {
    //color: #acacac !important;
  }
  &:active {
    color: #464646 !important;
  }
  &:focus {
    color: #464646 !important;
  }
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.username {
  text-transform: none;
}

.changepasscontainer {
  .modal-content {
    padding: 20px 50px;
    border-radius: 0px;
    .modal-header {
      padding: 0;
      border: 0;
      .modal-title {
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .changepassform label {
      margin-bottom: 2px;
    }
    .changepassform input {
      border: 1px solid #bababa;
      border-radius: 0px;
      color: #000;
      height: 40px;
    }

    .modal-footer {
      justify-content: center;
      padding: 10px;
      border-top: 0px;
      .cancel {
        background-color: #fdf2e1;
        border: 1px solid #e8decf;
        color: #000;
      }
      button {
        height: 36px;
      }
    }
  }
}

.error-message {
  color: red;
  font-family: mediumFont;
  font-weight: bold;
  font-size: 13px;
}

.sucess-message {
  color: green;
  font-family: mediumFont;
  font-weight: bold;
  font-size: 13px;
}

img.header-menu-icon {
  margin-right: 10px;
}

img.image-icon {
  padding: 8px 0px;
}

.btn_emergency {
  background-color: orange !important;
  color: white !important;
  &:hover {
    color: black !important;
    background-color: rgb(255, 166, 0) !important;
  }
}
.navbar .navbar-collapse.collapse.show {
  height: auto !important;
}

@media screen and (max-width: 768px) {
  .username {
    display: none;
  }
}

.broadcast_header {
  background: #f4f4f4;
  width: 50%;
  font-family: ABBvoice;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 999;
  right: 16px;
}
.marginRight{
  margin-right: 10px;
}
.inner-header{
  background: #0051ff14 8%;
  height: 45px;
  display: flex;
  justify-content: space-between;
}